import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/pipes/shared.module';
import { SimpleListPopupComponent } from './simple-list-popup.component';



@NgModule({
  declarations: [SimpleListPopupComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [SimpleListPopupComponent],
})
export class SimpleListPopupModule { }
