import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import seedColor from 'seed-color';

export interface ILog {
    label: string;
    message: string,
    args?: any[],
    type?: LogType;
}

export enum LogType {
    Normal = 'normal',
    Error = 'error'
}

@Injectable({
    providedIn: 'root'
})
export class LogService {

    constructor() { }

    public log(details: ILog, hexColor?: string) {
        if (!environment.production) {
            const tagColor = `background: #222; border-radius: .15rem; padding: .3rem .3rem; color: ${hexColor || seedColor(details.label).toHex()
                }`;
            const mainColor = `background: auto; color: auto`;
            console.log(`%c[${details.label}]%c ${details.message}`, tagColor, mainColor, ...(details.args || []));
        } else if (details.type === LogType.Error) {
            // TODO: Report the log into firebase crash-lytics for analysis.
        }
    }

}