import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NavController } from "@ionic/angular";
import firebase from 'firebase/app';
import * as moment from "moment";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { take, first } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { IUser, IUserCached, IUserFriendsList, MembershipStatus, UserFriendStatus } from "../interfaces/IUser";
import { UtilityHelper } from "../utils/utility-helper.util";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private USER_UPDATE_TIMEOUT = 15;
    private cachedUsers: IUserCached[] = [];

    static details: IUser = null;

    private $userDetailsSubscription = new ReplaySubject<IUser>(1);
    private $userFirebaseUpdatesSubscription: Subscription;

    constructor(
        private authService: AuthService,
        private afStore: AngularFirestore,
        private afFunctions: AngularFireFunctions,
        private navControl: NavController
    ) { this.listenForAuthUpdates(); }

    private listenForAuthUpdates() {
        this.authService.listenForAuthState().subscribe(async (res) => {
            if (this.$userFirebaseUpdatesSubscription) { this.$userFirebaseUpdatesSubscription.unsubscribe(); }

            if (!res) {
                this.$userDetailsSubscription.next(null);
            } else {
                await this.updateDetails({
                    uid: res.uid,
                    meta: {
                        last_online: firebase.firestore.Timestamp.now()
                    }
                });

                this.$userFirebaseUpdatesSubscription = this.getUserDetailsListener(res.uid).subscribe((r) => {
                    UserService.details = r;
                    if (r) {
                        this.$userDetailsSubscription.next(r);
                    } else {
                        // No Details exist so create them.
                        this.updateDetails({
                            uid: res.uid,
                            name: res.email.split('@')[0],
                            email: res.email
                        });
                    }
                });
            }
        });
    }

    /**
     * @description Gets user details update listener.
     */
    public getUserDetailsListener(uid: string) {
        return this.afStore.doc<IUser>(`users/${uid}`).valueChanges();
    }

    /**
     * @description Get our current user details
     */
    public async getUserDetails(uid: string, forceUpdate?: boolean) {
        // If we are after our details just return what we already have.
        if (uid === AuthService.auth?.uid && UserService.details != null) return UserService.details;
        if (!uid) return null;

        let cachedUser = this.cachedUsers.find((x) => x.user && x.user.uid === uid);

        if (forceUpdate && cachedUser) {
            this.cachedUsers.splice(this.cachedUsers.findIndex((x) => x == cachedUser), 1);
            cachedUser = null;
        }

        // If it is a new user then create an array item for them.
        if (!cachedUser) {
            cachedUser = {
                fetched_at: moment(),
                user: null
            };
            this.cachedUsers.push(cachedUser);
        }

        // If the user is new or out of date then we'll update their details.
        if (!cachedUser.user || cachedUser.fetched_at.diff(moment(), 'minutes') > this.USER_UPDATE_TIMEOUT) {
            try {
                const fetchedUser = await UtilityHelper.Snapshot<firebase.firestore.DocumentSnapshot<IUser>>(this.afStore.doc<IUser>(`users/${uid}`).get());
                cachedUser.user = fetchedUser.data();
            }
            catch {
                console.warn(`Could not fetch user details for: ${uid}`);
            }
        }

        return cachedUser.user;
    }

    /**
     * @description Update a users details
     */
    public updateDetails(user: IUser) {
        return this.afStore.doc(`users/${user.uid}`).set(user, { merge: true });
    }

    /**
     * @description Get our current user details
     */
    public getOurDetails() {
        return this.$userDetailsSubscription.asObservable();
    }

    /**
     * @description Adds friend
     * @param uid 
     * @returns  
     */
    public async addFriend(uid: string) {
        const value = await UtilityHelper.Snapshot(
            this.afFunctions.httpsCallable('addFriend')({
                user: uid
            })
        );
        return value;
    }

    /**
     * @description Removes friend
     * @param uid 
     * @returns  
     */
    public async removeFriend(uid: string) {
        const value = await UtilityHelper.Snapshot(
            this.afFunctions.httpsCallable('removeFriend')({
                user: uid
            })
        );
        return value;
    }

    /**
     * @description Returns true if we are friends with a given person.
     */
    isFriendsWith(user: IUser) {
        if (!user || !user.friends) return false;
        return (user.friends[AuthService.auth.uid] || 0) === UserFriendStatus.Friends;
    }

    /**
     * @description Returns true if we are friends with a given person.
     */
    getFriendStatus(user: IUser) {
        if (!user || !user.friends) return 0;
        return user.friends[AuthService.auth.uid] || 0;
    }

    /**
     * @description Log the current user out and send to the home screen.
     */
    logout() {
        this.authService.signout().then(() => {
            this.navControl.navigateBack('/');
        });
    }

    /**
     * @description Determines whether subscription is valid or not
     * @param user 
     */
    hasSubscription(user: IUser) {
        // Return false if we dont have a subscription.
        if (!user.membership) return false;
        // Return false if our subscription is a test but we're on production.
        if (!(!environment.production || location.hostname.startsWith(environment.devPrefix)) && !user.membership.live) return false;
        // Return false if the subscription is inactive
        if (user.membership.status === MembershipStatus.INACTIVE) return false;
        // Return false if the end date is in the past.
        if ((user.membership.sub_end * 1000) < Date.now()) return false;

        return user.membership;
    }

    sendVerificationEmail() {
        return AuthService.auth.sendEmailVerification();
    }
}