import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { DEFAULT_THEMES } from "../constants/themes.const";
import { MediaHelper } from "../utils/media-helper.util";
import { UtilityHelper } from "../utils/utility-helper.util";
import { LogService } from "./log.service";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    private defaultColor = DEFAULT_THEMES[0].accent;
    private defaultSecondaryColor = DEFAULT_THEMES[0].accentSecondary;
    private primaryBrightnessShift = 30;

    constructor(
        private userService: UserService,
        private platform: Platform
    ) {
        this.userService.getOurDetails().subscribe((e) => {
            const primary = (e && e.prefs && e.prefs.accent) ? e.prefs.accent : this.defaultColor;
            const primaryAlt = (e && e.prefs && e.prefs.accent) ? e.prefs.accent_alt : null;
            const secondary = (e && e.prefs && e.prefs.accentSecondary) ? e.prefs.accentSecondary : this.defaultSecondaryColor;

            this.setAccent(primary, primaryAlt, secondary);
        })
    }

    /**
     * @description Sets accent colour of the application
     * @param rgb 
     */
    private setAccent(rgb: string, rgb_alt?: string, rgb_secondary?: string) {
        const hex = MediaHelper.rgbToHex(rgb);

        this.setCssVariables({
            '--theme-primary': rgb,
            '--theme-primary-alt': rgb_alt || UtilityHelper.HexToRGB(
                UtilityHelper.ColourLightness(UtilityHelper.RGBToHex(rgb), this.primaryBrightnessShift)
            ) as string,
            '--theme-secondary': rgb_secondary,
        });

        if (this.platform.is('desktop') || (this.platform.is('mobileweb') && !this.platform.is('pwa'))) {
            this.setStatusBar(hex);
        }
    }

    /**
     * @description Sets status bar colour
     * @param hex 
     */
    private setStatusBar(hex: string) {
        const oldComp = document.querySelector('meta[name=theme-color]');
        if (oldComp) { oldComp.remove(); }
        const metaElement = document.createElement('meta');
        metaElement.name = 'theme-color';
        metaElement.content = hex;
        document.querySelector('head').appendChild(metaElement);
    }

    /**
     * @description Sets css variables into the document
     * @param vars Key: [value] 
     */
    private setCssVariables(vars: { [key: string]: string }) {
        let sheetElement = document.querySelector('style[id=custom-theme]');

        if (!sheetElement) {
            sheetElement = document.createElement('style');
            sheetElement.id = 'custom-theme';
            document.querySelector('head').appendChild(sheetElement);
        }

        const sheet = (sheetElement as HTMLStyleElement).sheet;
        let rules = '';

        Object.keys(vars).forEach((e) => {
            rules += `${e}: ${vars[e]};\n`;
        });

        if (sheet.cssRules.length > 0) sheet.deleteRule(0);
        sheet.insertRule(`:root {${rules}}`);
    }


}