import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ActionInteractionLook } from "./IActionInteraction";

@Component({
    template: ''
})
export abstract class ActionInteraction {
    @Input() disabled: boolean = false;
    @Input() look: ActionInteractionLook | string = ActionInteractionLook.Rasied;
    @Output() action = new EventEmitter<any>();

    abstract isValid(): boolean;
}