import { AfterViewInit, ContentChild, Directive, ElementRef, EventEmitter, HostListener, NgZone, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Gesture, GestureController, Platform, PopoverController } from '@ionic/angular';
import { ContextMenuPopupComponent } from '../components/popups/context-menu/context-menu-popup.component';

@Directive({
    selector: '[rightClick]'
})
export class RightClickDirective implements AfterViewInit, OnDestroy {

    @Output() public rightClick = new EventEmitter<MouseEvent | TouchEvent>();
    @ContentChild('rightClickContext') private contextTemplate: TemplateRef<Object>;

    delay = 500;
    action: any;
    private gesture: Gesture;
    private longPressActive = false;

    constructor(
        private popoverController: PopoverController,
        private platform: Platform,
        private gestureController: GestureController,
        private zone: NgZone,
        private element: ElementRef<HTMLElement>
    ) { }

    ngAfterViewInit() {
        if (!this.platform.is('mobile')) return;
        // this.gesture = this.gestureController.create({
        //     el: this.element.nativeElement,
        //     threshold: 0,
        //     gestureName: 'long-press',
        //     passive: true,
        //     onStart: (ev) => {
        //         this.longPressActive = true;
        //         this.longPressAction(ev.event as TouchEvent);
        //     },
        //     onEnd: (ev) => {
        //         this.longPressActive = false;
        //     }
        // });
        // this.gesture.enable(true);
    }

    ngOnDestroy() {
        if (this.gesture) this.gesture.destroy();
    }

    private longPressAction(ev: TouchEvent) {
        if (this.action) {
            clearInterval(this.action);
        }
        this.action = setTimeout(() => {
            if (this.longPressActive === true) {
                this.longPressActive = false;
                this.fireEvent(ev);
            }
        }, this.delay);
    }

    @HostListener('contextmenu', ['$event'])
    public async onClick(ev: MouseEvent) {
        ev.preventDefault();
        this.fireEvent(ev);
    }

    async fireEvent(ev: MouseEvent | TouchEvent) {
        if (!this.contextTemplate) {
            this.rightClick.emit(ev);
        } else {
            const popover = await this.popoverController.create({
                component: ContextMenuPopupComponent,
                event: ev,
                translucent: false,
                componentProps: {
                    template: this.contextTemplate
                }
            });
            await popover.present();
        }
    }
}
