import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Location } from '@angular/common';
import { BehaviorSubject } from "rxjs";
import { IMeeting } from "../interfaces/IMeeting";
import { INavigationTopics, INavigationWorkspaces, NavigationPageType } from "../interfaces/INavigation";
import { ITopic } from "../interfaces/ITopic";
import { IWorkspace } from "../interfaces/IWorkspace";
import { WorkspaceServiceFB } from "./workspace.firebase.service";
import { LogService } from "./log.service";
import { ActivatedRoute } from "@angular/router";
import { PreferencesService } from "./preferences.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private $currentWorkspaceSub = new BehaviorSubject<INavigationWorkspaces>(null);
    private $currentTopicSub = new BehaviorSubject<INavigationTopics>(null);
    private $allMeetingsSub = new BehaviorSubject<IMeeting[]>([]);

    private $workspacePageSub = new BehaviorSubject<NavigationPageType>(NavigationPageType.Board);
    private $workspacePageOptionsSub = new BehaviorSubject<any>({});
    private $workspaceDrawerSub = new BehaviorSubject<boolean>(false);

    constructor(
        private workspaceService: WorkspaceServiceFB,
        private titleService: Title,
        private location: Location,
        private logService: LogService,
        private prefsService: PreferencesService
    ) {
        this.listenForNetworkWorkspaces();
        this.listenForNetworkTopics();
        this.listenForNetworkMeetings();

        this.prefsService.getSnapshot().then((e) => {
            if (e.navigation.last_page) {
                this.setWorkspacePage(e.navigation.last_page)
            }
        });
    }

    /**
     * @description Try and activate some workspace if we have any to do so.
     */
    public tryActivateSomeWorkspace() {
        const current = this.$currentWorkspaceSub.getValue();
        if (current && current.all.length > 0) {
            this.setWorkspace(current.all[0]);
        }
    }

    /**
     * @description Listens for networked workspaces from the API
     */
    private listenForNetworkWorkspaces() {
        this.workspaceService.getWorkspaces().subscribe((e) => {
            const current = this.$currentWorkspaceSub.value;
            let currentWorkspace = current && current.current ? e.find((x) => x.uid === current.current.uid) : null;

            const path = this.location.path().split('/');
            if (path[1] === 'w' || !path[1]) {
                if (!currentWorkspace && path.length >= 3 && e.length > 0) {
                    currentWorkspace = e.find((x) => x.slug === path[2]) || null;
                }

                this.setWorkspace(
                    currentWorkspace ? currentWorkspace : e ? e[0] : null, e
                );
            } else {
                this.setWorkspace(null, e);
            }
        });
    }

    /**
     * @description Listens for networked topics from the API
     */
    private listenForNetworkTopics() {
        this.workspaceService.getTopics().subscribe((e) => {
            const current = this.$currentTopicSub.value;
            let currentTopic = current && current.current ? e.find((x) => x.uid === current.current.uid) : null;

            const path = this.location.path().split('/');
            if (path[1] === 'w' || !path[1]) {
                if (!currentTopic && path.length >= 4 && e.length > 0) {
                    currentTopic = e.find((x) => x.slug === path[3]) || null;
                }

                this.setTopic(
                    currentTopic ? currentTopic : e ? e.sort((a, b) => a.order - b.order)[0] : null, e
                );
            } else {
                this.setTopic(null, e);
            }
        });
    }

    /**
     * @description Listens for networked meetings from the API
     */
    private listenForNetworkMeetings() {
        this.workspaceService.getMeetings().subscribe((e) => {
            this.$allMeetingsSub.next(e);
        });
    }

    /**
     * @description Set the current workspace and other available workspaces.
     * @param workspace 
     * @param all 
     */
    public setWorkspace(workspace: IWorkspace, all?: any[]) {
        const sameAsExisting = this.$currentWorkspaceSub.value?.current?.uid == workspace?.uid;

        if (!sameAsExisting && workspace) {
            this.$currentTopicSub.next(null);
            this.workspaceService.listenForTopicsChanges(workspace.uid);
            this.workspaceService.listenForMeetingChanges(workspace.uid);
            this.workspaceService.listenForContentChanges(workspace.uid);
        }

        this.$currentWorkspaceSub.next({
            current: workspace,
            all: all || this.$currentWorkspaceSub.value?.all
        });
        this.refreshPageTitle();
    }

    /**
     * @description Refreshs page title to reflect workspace and topic.
     */
    private refreshPageTitle() {
        const workspace = this.$currentWorkspaceSub.value;
        const topic = this.$currentTopicSub.value;

        const page = this.$workspacePageSub.value;
        const options = this.$workspacePageOptionsSub.value;
        const optionsString = Object.keys(options).map((x) => `${x}=${options[x]}`).join('&');

        if (workspace && topic && workspace.current && topic.current) {
            this.titleService.setTitle(`${workspace.current.name} - ${topic.current.name}`);
            this.location.replaceState(`w/${workspace.current.slug}/${topic.current.slug}${page ? '/' + page : ''}${optionsString ? '?' + optionsString : ''}`);
        } else if (workspace && workspace.current) {
            // this.titleService.setTitle(`${workspace.current.name}`);
            // this.location.replaceState(`${workspace.current.slug}`);
        }
    }

    /**
     * @description Gets current workspace and other available workspaces
     * @returns  
     */
    public listenForWorkspaces() {
        return this.$currentWorkspaceSub.asObservable();
    }

    /**
     * @description Sets the current topic and also other available topics 
     * @param topic 
     * @param all 
     */
    public setTopic(topic: ITopic, all?: ITopic[]) {
        this.$currentTopicSub.next({
            current: topic,
            all: all || this.$currentTopicSub.value?.all
        });

        this.refreshPageTitle();
    }

    /**
     * @description Gets current topic and other available topics
     * @returns  
     */
    public listenForTopics() {
        return this.$currentTopicSub.asObservable();
    }

    /**
     * @description Sets workspace drawer state
     * @param state 
     */
    public setWorkspaceDrawer(state: boolean) {
        this.$workspaceDrawerSub.next(state);
    }

    /**
     * @description Listen for the drawer state
     * @returns  
     */
    public listenForWorkspaceDrawer() {
        return this.$workspaceDrawerSub.asObservable();
    }

    /**
     * @description Listen for the page state of the workspace
     * @returns  
     */
    public listenForWorkspacePage() {
        return this.$workspacePageSub.asObservable();
    }

    /**
     * @description Listens for workspace page options
     * @returns  
     */
    public listenForWorkspacePageOptions() {
        return this.$workspacePageOptionsSub.asObservable();
    }

    /**
     * @description Set the page to navigate to on the workspace settings
     */
    public setWorkspacePage(page?: NavigationPageType, options?: any) {
        if (page) {
            this.prefsService.update({
                navigation: {
                    last_page: page
                }
            });
            this.$workspacePageSub.next(page);
        }

        if (!options && page && this.$workspacePageSub.value != page) {
            this.$workspacePageOptionsSub.next({});
        } else {
            const combinedOptions = {
                ...(this.$workspacePageOptionsSub.value || {}),
                ...(options || {})
            };
            Object.keys(combinedOptions).forEach((e) => {
                if (combinedOptions[e] === undefined || combinedOptions[e] === null) delete combinedOptions[e];
            })
            this.$workspacePageOptionsSub.next(combinedOptions);
        }

        this.refreshPageTitle();
    }

    /**
     * @description Gets current meeting rooms within this workspace
     * @returns  
     */
    public listenForMeetings() {
        return this.$allMeetingsSub.asObservable();
    }

    /**
     * @description Gets a users role for the current workspace
     * @param user 
     * @returns  
     */
    public getRole(user?: string) {
        const current = this.$currentWorkspaceSub.value?.current;
        if (current && (current.users.includes(user) || !user)) {
            return this.workspaceService.getUserRole(current, user);
        } else {
            return null;
        }
    }

    /**
     * @description Returns true if user has a permission for their current workspace.
     * @param user 
     */
    public hasPermission(permission: string, user?: string) {
        const current = this.$currentWorkspaceSub.value?.current;

        if (current && (current.users.includes(user) || !user)) {
            return this.workspaceService.hasPermission(permission, current, user);
        } else {
            return false;
        }
    }

}