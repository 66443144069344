import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/pipes/shared.module';
import { SimpleTextPopupComponent } from './simple-text-popup.component';
import { FormsModule } from '@angular/forms';
import { ActionInputModule } from '../../simple/action-interaction/action-input/action-input.module';
import { ActionButtonModule } from '../../simple/action-interaction/action-button/action-button.module';



@NgModule({
  declarations: [SimpleTextPopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,

    ActionInputModule,
    ActionButtonModule
  ],
  exports: [SimpleTextPopupComponent],
})
export class SimpleTextPopupModule { }
