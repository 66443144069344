import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController, PopoverController } from '@ionic/angular';
import { fadeAnimations } from 'src/app/animations/baked-animations';
import { ContentService } from 'src/app/services/content.service';
import { DisplayInfoService } from 'src/app/services/display-info.service';
import { ContentModalComponent } from '../../modals/content-modal/content-modal.component';
import { ActionInputComponent } from '../../simple/action-interaction/action-input/action-input.component';
import firebase from 'firebase/app';
import { UtilityHelper } from 'src/app/utils/utility-helper.util';

@Component({
  selector: 'app-new-content-popup',
  templateUrl: './new-content-popup.component.html',
  styleUrls: ['./new-content-popup.component.scss'],
  animations: [fadeAnimations]
})
export class NewContentPopupComponent implements OnInit {

  @ViewChild(ActionInputComponent) input: ActionInputComponent;

  @Input() location;
  name = '';
  type = '';

  constructor(
    private contentService: ContentService,
    private modalController: ModalController,
    private displayInfoService: DisplayInfoService,
    private popoverController: PopoverController
  ) { }

  ngOnInit() { }

  selectType(val: string) {
    this.type = val;

    setTimeout(() => {
      this.input.inputElement.nativeElement.focus();
    }, 250);
  }

  async create() {
    if (this.name.length < 3) {
      this.displayInfoService.showToast('Name needs to be longer than 3 characters');
      return;
    }

    this.popoverController.dismiss();

    const uid = await this.contentService.setContent({
      name: this.name,
      parent: this.location,
      type: this.type,
      created: firebase.firestore.FieldValue.serverTimestamp()
    } as any);

    await UtilityHelper.Wait(250);

    if (this.type === 'single') {
      this.modalController.create({
        component: ContentModalComponent,
        componentProps: {
          contentUID: uid
        },
        cssClass: 'auto-height bottom-sheet',
        swipeToClose: true,
        keyboardClose: true,
      }).then((e) => e.present());
    }
  }

}
