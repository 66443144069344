import { AfterContentInit, Component, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { IUser } from 'src/app/interfaces/IUser';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';
import { UserProfileModalComponent } from '../../modals/user-profile-modal/user-profile-modal.component';
import { IHoverLinkOption } from '../../simple/hover-link/hover-link.component';

@Component({
  selector: 'app-context-menu-popup',
  templateUrl: './context-menu-popup.component.html',
  styleUrls: ['./context-menu-popup.component.scss'],
})
export class ContextMenuPopupComponent implements OnInit {

  @Input() template: TemplateRef<Object>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    const ref = this.viewContainerRef.createEmbeddedView(this.template);
    ref.rootNodes.forEach(node => this.renderer.appendChild(this.element.nativeElement, node));
  }
}
