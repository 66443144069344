import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'initials'
})

export class InitialsPipe implements PipeTransform {

  constructor() { }

  transform(value: any): any {
    if (typeof value !== 'string' || value === '') {
      return;
    }
    const match = value.match(/[A-Z]/g);
    if (match) { return match.slice(0, 2).join(''); }
    const matchLowercase = value.match(/[a-z]/g);
    if (matchLowercase) { return matchLowercase.slice(0, 2).join(''); }
    return value;
  }
}
