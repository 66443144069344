import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class DisplayInfoService {

    constructor(
        private toastController: ToastController
    ) { }

    /**
     * @description Shows toast
     * @param message Toast Message
     * @param [duration] Duration of message to last
     * @param [buttons] Buttons to show on toast
     */
    showToast(message: string, duration?: number, buttons?: (string | any)[]) {
        this.toastController.create({
            message,
            duration: duration || 2000,
            buttons
        }).then((e) => e.present());
    }

}