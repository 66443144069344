import { animation, animate, style, keyframes, transition, trigger, useAnimation } from '@angular/animations';

export const fadeIn = animation([
    style({ opacity: 0 }),
    animate(
        '.2s ease',
        keyframes([
            style({ opacity: 0, }),
            style({ opacity: 1 }),
        ])
    )
]);

export const delayedFadeIn = animation([
    style({ opacity: 0 }),
    animate(
        '.4s ease',
        keyframes([
            style({ opacity: 0, offset: .5 }),
            style({ opacity: 1, offset: 1 }),
        ])
    )
]);

export const fadeOut = animation([
    style({ opacity: 1 }),
    animate(
        '.1s ease',
        keyframes([
            style({ opacity: 1 }),
            style({ opacity: 0 }),
        ])
    )
]);

export const popIn = animation([
    style({ opacity: 0, transform: 'scale(.5)' }),
    animate(
        '.2s ease',
        keyframes([
            style({ opacity: 0, transform: 'scale(.5)', offset: 0 }),
            style({ opacity: 1, transform: 'scale(1.1)', offset: .3 }),
            style({ opacity: 1, transform: 'scale(1)', offset: 1 }),
        ])
    )
]);

export const popOut = animation([
    style({ opacity: 1, transform: 'scale(1)' }),
    animate(
        '.2s ease',
        keyframes([
            style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
            style({ opacity: 1, transform: 'scale(1.1)', offset: .3 }),
            style({ opacity: 0, transform: 'scale(.5)', offset: 1 }),
        ])
    )
]);

export const popInSlow = animation([
    style({ opacity: 0, transform: 'scale(.5)' }),
    animate(
        '.4s ease',
        keyframes([
            style({ opacity: 0, transform: 'scale(.5)', offset: 0 }),
            style({ opacity: 1, transform: 'scale(1.1)', offset: .4 }),
            style({ opacity: 1, transform: 'scale(1)', offset: 1 }),
        ])
    )
]);

export const popOutSlow = animation([
    style({ opacity: 1, transform: 'scale(1)' }),
    animate(
        '.3s ease',
        keyframes([
            style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
            style({ opacity: 0, transform: 'scale(.5)', offset: 1 }),
        ])
    )
]);