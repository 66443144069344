import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileModalModule } from './user-profile-modal/user-profile-modal.module';
import { UserProfileModalComponent } from './user-profile-modal/user-profile-modal.component';
import { JoinCallModalModule } from './join-call-modal/join-call-modal.module';
import { JoinCallModalComponent } from './join-call-modal/join-call-modal.component';
import { ContentModalModule } from './content-modal/content-modal.module';
import { ContentModalComponent } from './content-modal/content-modal.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    UserProfileModalModule,
    JoinCallModalModule,
    ContentModalModule
  ],
  entryComponents: [
    UserProfileModalComponent,
    JoinCallModalComponent,
    ContentModalComponent
  ]
})
export class ModalsModule { }
