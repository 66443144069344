import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CustomScrollbarDirective } from "../directives/custom-scrollbar.directive";
import { DoubleClickDirective } from "../directives/double-click.directive";
import { PermissionDirective } from "../directives/permissions.directive";
import { RightClickDirective } from "../directives/right-click.directive";
import { TooltipDirective } from "../directives/tooltip.directive";
import { EllipsisPipe } from "./ellipsis.pipe";
import { FileSizePipe } from "./file-size.pipe";
import { InitialsPipe } from "./initials.pipe";
import { PermissionsPipe } from "./permissions.pipe";
import { ProPipe } from "./pro.pipe";
import { SafeHTMLPipe } from "./safeHtml.pipe";
import { TimePipe } from "./time.pipe";
import { UserDetailsPipe } from "./userdetails.pipe";

const includeShared = [
    // Pipes
    InitialsPipe,
    UserDetailsPipe,
    PermissionsPipe,
    TimePipe,
    ProPipe,
    SafeHTMLPipe,
    EllipsisPipe,
    FileSizePipe,

    // Directives
    TooltipDirective,
    DoubleClickDirective,
    RightClickDirective,
    PermissionDirective,
    CustomScrollbarDirective
];

@NgModule({
    declarations: includeShared,
    imports: [CommonModule,],
    exports: includeShared
})
export class SharedModule { }
