import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fadeAnimations, popAnimations } from 'src/app/animations/baked-animations';
import { ActionInteraction } from '../action-interaction.base';
import { ActionInteractionLook } from '../IActionInteraction';

export interface IDropdownOption {
  icon?: string;
  label: string;
  data?: any;
}

@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss', '../action-interaction.base.scss'],
  animations: [popAnimations, fadeAnimations]
})
export class ActionDropdownComponent extends ActionInteraction {

  @ViewChild('list') listElement: ElementRef;

  @Input() look: ActionInteractionLook = ActionInteractionLook.Flat;

  _options: IDropdownOption[] = [];

  @Input() set options(value: IDropdownOption[]) {
    this._options = value;

    if (!this._selected && value.length > 0) {
      this.selectOption(value[0]);
    }
  };

  _selected: IDropdownOption;

  @Input() set selected(val: IDropdownOption | any) {
    const option = this._options.find((x) => x === val);
    if (option) {
      this._selected = option;
    } else if (val !== null) {
      const optionValue = this._options.find((x) => x.data === val);
      this._selected = optionValue;
    } else {
      this._selected = null;
    }
  }
  get selected() {
    return this._selected;
  };


  @Output() selectedChange = new EventEmitter<IDropdownOption>();
  @Input() searchable: boolean = false;
  @Input() showArrow: boolean = true;

  @Input() isOpen: boolean = false;
  listenForClose = false;
  @Input() openAbove = false

  private _label = '';
  set label(val: string) {
    this._label = val;
  }

  get label() {
    if (!this.searchable) {
      if (!this._selected) return 'N/A';
      return this._selected.label;
    } else {
      return this._label;
    }
  }

  constructor(
    private element: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() { }

  isValid(): boolean {
    return true;
  }

  selectOption(option: IDropdownOption) {
    this._selected = option;
    this.selectedChange.emit(this._selected);
  }

  openDropdown() {
    if (!this.isOpen) this.toggle();
  }

  toggle() {
    this.isOpen = !this.isOpen;

    if (this.isOpen && this._selected) {
      requestAnimationFrame(() => {
        const list: HTMLElement = this.listElement.nativeElement;
        const selected = list.querySelector<HTMLElement>('.selected');
        selected.scrollIntoView({ behavior: 'smooth' });
        this.listenForClose = true;
      })
    }
  }

  @HostListener('window:click', ['$event'])
  blur(e: MouseEvent) {
    if (this.isOpen && this.listenForClose) {
      this.isOpen = false;
      this.listenForClose = false;

      if (e.target && this.element.nativeElement.contains(e.target as HTMLElement) && document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    };
  }
}
