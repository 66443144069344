import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[doubleClick]'
})
export class DoubleClickDirective {

    @Output() public doubleClick = new EventEmitter<MouseEvent>();
    private doubleTapSpeed = 300;

    private isDouble = false;
    private timeout;

    constructor() { }


    @HostListener('mousedown', ['$event'])
    public onClick(e: MouseEvent) {
        if (e.button !== 0) return;

        if (!this.isDouble) {
            this.isDouble = true;
            this.timeout = setTimeout(() => {
                this.isDouble = false;
            }, this.doubleTapSpeed);
            return;
        }

        if (this.timeout != undefined) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }

        this.doubleClick.emit(e);
        this.isDouble = false;
    }
}
