import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { IMeeting } from 'src/app/interfaces/IMeeting';
import { IWorkspace } from 'src/app/interfaces/IWorkspace';
import { AuthService } from 'src/app/services/auth.service';
import { DisplayInfoService } from 'src/app/services/display-info.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { WorkspaceServiceFB } from 'src/app/services/workspace.firebase.service';
import { MediaHelper } from 'src/app/utils/media-helper.util';

@Component({
  selector: 'app-inspect-meeting-popup',
  templateUrl: './inspect-meeting-popup.component.html',
  styleUrls: ['./inspect-meeting-popup.component.scss'],
})
export class InspectMeetingPopupComponent implements OnInit {

  @Input() meeting: IMeeting = null;
  @Input() workspace: IWorkspace = null;
  @Input() simplify: boolean = false;

  constructor(
    private workspaceService: WorkspaceServiceFB,
    private popoverController: PopoverController,
    private displayInfoService: DisplayInfoService,
    private interactionService: InteractionService
  ) { }

  ngOnInit() { }

  onAction(id: string, data?: any) {
    if (id === 'share') {
      this.shareMeeting();
    } else if (id === 'delete') {
      this.deleteMeeting();
    } else if (id === 'share-remove') {
      this.stopSharingMeeting();
    } else if (id === 'test') {
      console.log('Registering Guest...');
      this.workspaceService.registerMeetingGuest(this.workspace.slug, this.meeting.slug, this.meeting.shared.secret, AuthService.auth.uid).then((e) => console.log(e));
    }

    this.popoverController.dismiss(data, id)
  }

  copyMeetingLink(secret: string) {
    MediaHelper.copyToClipboard(
      `${location.origin}/meeting/${this.workspace.slug}/${this.meeting.slug}?s=${secret}`
    );
    this.displayInfoService.showToast('Meeting link copied!');
  }

  async shareMeeting() {
    const meetingSecret = await this.workspaceService.shareMeeting(this.workspace.uid, this.meeting);
    if (meetingSecret) {
      this.copyMeetingLink(meetingSecret);
    } else {
      this.displayInfoService.showToast('Failed to share meeting.');
    }
  }

  async stopSharingMeeting() {
    const result = await this.interactionService.showConfirmation(
      'Stop Sharing',
      `Are you sure you want to stop sharing this meeting? All previous links will become invalid.`, [
      {
        key: 'cancel',
        label: 'Cancel'
      },
      {
        key: 'stop',
        label: 'Stop Sharing'
      }
    ]);

    if (result === 'stop') {
      this.workspaceService.stopSharingMeeting(this.workspace.uid, this.meeting)
        .then((e) => this.displayInfoService.showToast('Meeting link disabled.'));
    }
  }

  async deleteMeeting() {
    const result = await this.interactionService.showConfirmation(
      'Delete Meeting',
      `Are you sure you want to delete this meeting?`, [
      {
        key: 'cancel',
        label: 'Cancel'
      },
      {
        key: 'delete',
        label: 'Delete'
      }
    ]);

    if (result === 'delete') {
      this.workspaceService.deleteMeeting(this.meeting, this.workspace.uid)
        .finally(() => {
          this.displayInfoService.showToast('Meeting Deleted.');
      });
    }
  }

}
