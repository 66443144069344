import { ChangeDetectorRef, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { IWorkspace } from '../interfaces/IWorkspace';
import { NavigationService } from '../services/navigation.service';
import { WorkspaceServiceFB } from '../services/workspace.firebase.service';

@Pipe({
    name: 'hasPermission'
})

export class PermissionsPipe implements PipeTransform, OnDestroy {

    permissions: string[] = [];
    workspace: IWorkspace;
    isShown = true;

    workspaceSub: Subscription;

    constructor(
        private navService: NavigationService,
        private workspaceService: WorkspaceServiceFB,
        private changeRef: ChangeDetectorRef
    ) {
        this.workspaceSub = this.navService.listenForWorkspaces().subscribe((e) => {
            this.workspace = e && e.current;
            this.changeRef.markForCheck();
        });
    }

    transform(permissions: string[] | string, permissionsAfter?: string[] | string): any {
        this.permissions = permissionsAfter ?
            typeof permissionsAfter === 'string' ? [permissionsAfter] : permissionsAfter :
            typeof permissions === 'string' ? [permissions] : permissions;

        return this.checkPermissions();
    }

    ngOnDestroy() {
        if (this.workspaceSub) this.workspaceSub.unsubscribe();
    }

    checkPermissions() {
        if (!this.workspace || !this.permissions || this.permissions.length === 0) return false;

        let passed = [];

        for (let i = 0; i < this.permissions.length; i++) {
            const val = this.permissions[i];
            const perm = val.startsWith('!') ? val.slice(1) : val;
            const perm_manager = perm.split('_')[0] + '_manage';

            const hasPermission = (this.workspaceService.hasPermission(perm, this.workspace) || this.workspaceService.hasPermission(perm_manager, this.workspace)) === true;
            passed.push(hasPermission === !val.startsWith('!'));
        }


        return passed.filter((x) => x).length > 0;
    }
}
