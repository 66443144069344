export class AudioHelper {

    public static PlaySound(path: string, volume?: number) {
        if (!path) return;
        const sound = document.createElement('audio');
        sound.src = path;
        sound.volume = volume ? volume : 0.5;
        document.body.appendChild(sound);
        sound.onended = () => {
            sound.remove();
        }
        sound.play();
    }
}

export class AudioClips {
    public static Meeting = {
        start: '/assets/sound/meeting/user_joined.ogg',
        leave: '/assets/sound/meeting/user_left.ogg',
        user_joined: '/assets/sound/meeting/user_joined.ogg',
        user_left: '/assets/sound/meeting/user_left.ogg',
        screen_off: '/assets/sound/meeting/screen_off.ogg',
        screen_on: '/assets/sound/meeting/screen_on.ogg',
        device_on: '/assets/sound/meeting/device_off.ogg',
        device_off: '/assets/sound/meeting/device_on.ogg',
        device_error: '/assets/sound/meeting/leave.ogg',
    }
}