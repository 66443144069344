import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Observable, ReplaySubject } from "rxjs";
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client'
import { environment } from "src/environments/environment";
import { LogService } from "./log.service";

@Injectable({
    providedIn: 'root'
})
export abstract class SocketService {

    abstract SOCKET_HOST: string;
    abstract SOCKET_OPTIONS: Partial<ManagerOptions & SocketOptions>;

    protected connectionStatus = new ReplaySubject<boolean>(1);

    private socket: Socket;

    protected id: string;

    constructor(
        public logService: LogService
    ) { }

    /**
     * @description Initialise the socket with the server and connect.
     */
    protected connect() {
        return new Promise((res) => {
            this.socket = io(this.SOCKET_HOST, this.SOCKET_OPTIONS);

            this.socket.on('connect', () => {
                this.logService.log({
                    label: 'Socket',
                    message: 'Connected',
                });
                this.connectionStatus.next(true);
                this.id = this.socket.id;
                res(true);
            });

            this.socket.on('disconnect', () => {
                this.logService.log({
                    label: 'Socket',
                    message: 'Disconnected',
                });
                this.connectionStatus.next(false);
                this.id = null;
                res(false);
            });

            this.socket.on('room-update', (data) => {
            });
        });
    }

    protected join(room: string, id: string) {
        this.emit('room-join', { room, id });
    }

    protected leave() {
        this.emit('room-leave', {});
    }

    /**
     * @description Listens for an event from the socket server.
     */
    protected listen(event: string) {
        return new Observable((sub) => {
            this.socket.on(event, (data) => {
                sub.next(data);
            })
        });
    }

    /**
     * @description Emits some data onto the socket connection.
     */
    protected emit(event: string, data: any) {
        this.socket.emit(event, data);
    }

    /**
     * @description Gets socket being used.
     */
    protected getSocket() {
        return this.socket;
    }

}