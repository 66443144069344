import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { IContent } from 'src/app/interfaces/IContent';
import { IMeeting } from 'src/app/interfaces/IMeeting';
import { IWorkspace } from 'src/app/interfaces/IWorkspace';
import { AuthService } from 'src/app/services/auth.service';
import { ContentService } from 'src/app/services/content.service';
import { DisplayInfoService } from 'src/app/services/display-info.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { MediaHelper } from 'src/app/utils/media-helper.util';
import { ContentModalComponent } from '../../modals/content-modal/content-modal.component';

@Component({
  selector: 'app-inspect-content-popup',
  templateUrl: './inspect-content-popup.component.html',
  styleUrls: ['./inspect-content-popup.component.scss'],
})
export class InspectContentPopupComponent implements OnInit {

  @Input() content: IContent = null;

  constructor(
    private contentService: ContentService,
    private popoverController: PopoverController,
    private displayInfoService: DisplayInfoService,
    private interactionService: InteractionService,
    private modalController: ModalController
  ) { }

  ngOnInit() { }

  onAction(id: string, data?: any) {
    if (id === 'delete') {
      this.deleteItem();
    } if (id === 'download') {
      this.downloadItem();
    } if (id === 'copy') {
      this.copyLink();
    } if (id === 'edit') {
      this.modalController.create({
        component: ContentModalComponent,
        componentProps: {
          parent: this.content.parent,
          content: this.content
        },
        cssClass: 'auto-height bottom-sheet',
        swipeToClose: true,
        keyboardClose: true,
      }).then((e) => e.present());
    }

    this.popoverController.dismiss(data, id)
  }

  copyLink() {
    if (this.content.versions?.length > 0) {
      MediaHelper.copyToClipboard(this.content.versions[0].link);
      this.displayInfoService.showToast('Direct link copied to clipboard.');
    } else {
      this.displayInfoService.showToast('There are no versions of this item yet...');
    }
  }

  downloadItem() {
    if (this.content.versions?.length > 0) {
      window.open(this.content.versions[0].link, '_blank');
    } else {
      this.displayInfoService.showToast('There are no versions of this item yet...');
    }
  }

  // copyMeetingLink(secret: string) {
  //   MediaHelper.copyToClipboard(
  //     `${location.origin}/meeting/${this.workspace.slug}/${this.meeting.slug}?s=${secret}`
  //   );
  //   this.displayInfoService.showToast('Meeting link copied!');
  // }

  // async shareMeeting() {
  //   const meetingSecret = await this.workspaceService.shareMeeting(this.workspace.uid, this.meeting);
  //   if (meetingSecret) {
  //     this.copyMeetingLink(meetingSecret);
  //   } else {
  //     this.displayInfoService.showToast('Failed to share meeting.');
  //   }
  // }

  // async stopSharingMeeting() {
  //   const result = await this.interactionService.showConfirmation(
  //     'Stop Sharing',
  //     `Are you sure you want to stop sharing this meeting? All previous links will become invalid.`, [
  //     {
  //       key: 'cancel',
  //       label: 'Cancel'
  //     },
  //     {
  //       key: 'stop',
  //       label: 'Stop Sharing'
  //     }
  //   ]);

  //   if (result === 'stop') {
  //     this.workspaceService.stopSharingMeeting(this.workspace.uid, this.meeting)
  //       .then((e) => this.displayInfoService.showToast('Meeting link disabled.'));
  //   }
  // }

  async deleteItem() {
    if (this.content.type === 'folder' && this.contentService.calculateItemSize(this.content).items > 0) {
      this.displayInfoService.showToast('You can only delete empty folders.');
      return;
    }

    const result = await this.interactionService.showConfirmation(
      `Delete ${this.content.type === 'folder' ? 'Folder' : 'Item'}`,
      `Are you sure you want to delete "${this.content.name}"?`, [
      {
        key: 'cancel',
        label: 'Cancel'
      },
      {
        key: 'delete',
        label: 'Delete'
      }
    ]);

    if (result === 'delete') {
      this.contentService.deleteContent(this.content)
        .finally(() => {
          this.displayInfoService.showToast(this.content.name + ' Deleted.');
        });
    }
  }

}
