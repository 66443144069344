import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { IPreferences } from "../interfaces/IPreferences";
import { UserService } from "./user.service";
import { Storage } from '@ionic/storage-angular';
import { first, last } from "rxjs/operators";
import { UtilityHelper } from "../utils/utility-helper.util";

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {

    readonly PREFERENCES_KEY = 'preferences';

    private default_prefs: IPreferences = {
        navigation: {
            chat_open: true,
            sidebar_open: true,
            last_page: null,
            chat_size: null
        },
        devices: {},
        sound: {
            notifications: 1.0
        }
    };
    private prefs: IPreferences;
    private prefsListener = new ReplaySubject<IPreferences>(1);

    constructor(private storage: Storage) {
        this.storage.create().then((e) => this.onPrefsHaveChanged());
    }

    /**
     * @description Called when the prefs have changed to push through the new details
     */
    private async onPrefsHaveChanged() {
        const stored = await this.storage.get(this.PREFERENCES_KEY);
        this.prefs = stored || this.default_prefs;
        this.prefsListener.next(this.prefs);
    }

    /**
     * @description Update the user preferences on the device.
     * @param prefs 
     */
    public async update(prefs: IPreferences) {
        const newPrefs = UtilityHelper.MergeDeep(this.prefs, prefs);
        await this.storage.set(this.PREFERENCES_KEY, newPrefs);
        this.onPrefsHaveChanged();
    }

    /**
     * @description Gets preferences listener.
     */
    public getListener() {
        return this.prefsListener.asObservable();
    }

    /**
     * @description Gets snapshot a of preferences.
     */
    public getSnapshot() {
        return this.prefsListener.pipe(first()).toPromise();
    }

}