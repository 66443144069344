import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButtonComponent } from './action-button.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [ActionButtonComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [ActionButtonComponent],
})
export class ActionButtonModule { }
