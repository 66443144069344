import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'time'
})

export class TimePipe implements PipeTransform {

    constructor() { }

    transform(value: firebase.default.firestore.Timestamp | Date, style?: string): any {
        if (!value) return;
        const time = value instanceof Date ? moment(value) : moment(value.toDate());

        if (!style) {
            return time.format(this.getTimeFormat() + ' - DD MMM YYYY')
        } else if (style === 'time') {
            return time.format(this.getTimeFormat())
        } else if (style === 'date') {
            return time.format('Do MMM YYYY')
        } else if (style === 'recent') {
            const now = moment();
            const today = now.clone().startOf('day');
            const yesterday = now.clone().subtract(1, 'days').startOf('day');
            const week = now.clone().subtract(7, 'days').startOf('day');

            if (time.isSame(today, 'day')) {
                // If is today
                return time.format(this.getTimeFormat());
            } else if (time.isSame(yesterday, 'day')) {
                // If is yesterday
                return 'Yesterday ' + time.format(this.getTimeFormat());
            } else if (time.isAfter(week)) {
                // If is this week
                return time.format('ddd ' + this.getTimeFormat());
            } else if (time.isSame(now, 'year')) {
                // If is this year
                return time.format('D MMM');
            } else {
                // If is over a year old.
                return time.format('MMM YYYY');
            }
        } else {
            return time.format(style);
        }
    }

    getTimeFormat() {
        return 'HH:mm';
    }
}
