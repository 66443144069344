import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import { take, switchMap, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { IUser } from '../interfaces/IUser';

export enum AllowUserTypes {
    Fresh = -1,
    All = 0,
    Guests = 1,
    LoggedIn = 5,
    UnVerified = 10,
    Verified = 50,
    VerifiedButUnauthorised = 70,
    Authorised = 75,
    Paying = 100
}

@Injectable()
export class LoggedInGuard implements CanActivate, CanActivateChild {

    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        private userService: UserService
    ) { }

    canActivate() {
        return this.auth.authState.pipe(
            take(1),
            switchMap(async (authState) => {
                if (!authState) { // check are user is logged in
                    this.router.navigate(['/register'])
                    return false
                }
                if (authState.providerData.length === 0) { // check claims
                    this.auth.signOut().then(() => {
                        this.router.navigate(['/register'])
                    })
                    return false
                }
                return true
            }),
        )
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.auth.authState.pipe(
            take(1),
            switchMap(async (authState) => {
                const state: AllowUserTypes[] | AllowUserTypes = childRoute.data.loginGuard?.allow;
                const allowStates: AllowUserTypes[] = state ? Array.isArray(state) ? state : [state] : [AllowUserTypes.All];

                const logoutGuests = childRoute.data.loginGuard?.logoutGuests;
                const redirect = childRoute.data?.loginGuard?.redirect || '/register';

                // If we are a guest account, and the route specifies we need to be logged out then do so.
                if (logoutGuests && authState && authState.providerData.length === 0) {
                    await this.auth.signOut();
                    authState = null;
                }

                const permissionsPassed: boolean[] = [];
                for (let i = 0; i < allowStates.length; i++) {
                    permissionsPassed.push(await this.checkAuthTypeIsAllowed(allowStates[i], authState));
                }

                // Only access if all states have passed.
                let shouldAllow = permissionsPassed.findIndex((x) => x == false) == -1;

                if (!shouldAllow) this.router.navigate([redirect]);
                return shouldAllow;
            }),
        )
    }

    async checkAuthTypeIsAllowed(type: AllowUserTypes, authState: any) {
        const allowState: AllowUserTypes = type || AllowUserTypes.All;
        let shouldAllow = false;
        const userDetails: IUser = await (authState ? this.userService.getUserDetails(authState.uid, true) : {} as any)

        switch (allowState) {
            default:
                shouldAllow = false;
                break;
            case AllowUserTypes.All:
                // Allow Everyone
                shouldAllow = true;
                break;
            case AllowUserTypes.Fresh:
                // Only allow non-logged in users
                shouldAllow = !authState;
                break;
            case AllowUserTypes.Guests:
                // Only allow guest users
                shouldAllow = authState && authState.providerData.length === 0;
                break;
            case AllowUserTypes.LoggedIn:
                // Only allow logged in, non-guest users
                shouldAllow = authState && authState.providerData.length > 0;
                break;
            case AllowUserTypes.UnVerified:
                // Only allow logged in, un-verified users
                shouldAllow = authState && (!authState.emailVerified || userDetails?.meta?.email_verified !== true);
                break;
            case AllowUserTypes.Verified:
                // Only allow logged in, verified users
                shouldAllow = authState && authState.emailVerified && userDetails?.meta?.email_verified === true;
                break;
            case AllowUserTypes.VerifiedButUnauthorised:
                // Only allow logged in, verified users that are also authorised
                shouldAllow = authState && authState.emailVerified && !userDetails?.meta?.authorised;
                break;
            case AllowUserTypes.Authorised:
                // Only allow logged in, verified users that are also authorised
                shouldAllow = authState && authState.emailVerified && userDetails?.meta?.authorised;
                break;
            case AllowUserTypes.Paying:
                // Only allow logged in, non-guest users
                const loggedIn = authState && authState.providerData.length > 0;
                if (!loggedIn) {
                    shouldAllow = false;
                } else {
                    shouldAllow = userDetails.membership?.live;
                }
                break;
        }

        return shouldAllow == true;
    }

}