import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActionInteraction } from '../action-interaction.base';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss', '../action-interaction.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonComponent extends ActionInteraction {

  @Input() loading = false;

  constructor() {
    super();
  }

  /**
   * @description Called when the button is pressed on
   * @param e Click Event
   */
  async onClick(e: MouseEvent) {
    if (!this.disabled) {
      this.action.emit(e);

    }
  }

  public isValid() {
    return true;
  }
}
