import * as moment from "moment";

export interface IUser {
    uid: string;
    name?: string;
    email?: string;
    photo?: string;
    friends?: IUserFriendsList;
    guest?: boolean;

    prefs?: IUserPreferences;
    meta?: IUserMeta;
    membership?: IUserMembership;
}

export interface IUserMembership {
    status: MembershipStatus;
    tier: MembershipTier;
    sub_start: number;
    sub_end: number;
    live: boolean;
}

export enum MembershipTier {
    NORMAL = '',
    PRO = 'PRO'
}

export enum MembershipStatus {
    INACTIVE = 'inactive',
    ACTIVE = 'active'
}

export interface IUserMeta {
    devices?: IUserDevice[];
    invitedBy?: string;
    video?: boolean;
    audio?: boolean;
    screen?: boolean;
    wantsNews?: boolean;
    authorised?: boolean;
    email_verified?: true | false | 'sent';
    last_online?: firebase.default.firestore.Timestamp;
}

export interface IUserDevice {
    uuid: string;
    token: string;
    notifications: boolean;
}

export interface IUserPreferences {
    accent?: string;
    accent_alt?: string;
    accentSecondary?: string;
}

export interface IUserCached {
    fetched_at: moment.Moment;
    user: IUser;
}

export interface IUserFriendsList {
    [key: string]: UserFriendStatus;
}

export enum UserFriendStatus {
    Blocked = -1,
    NotFriends = 0,
    PendingSent = 1,
    PendingReceived = 2,
    Friends = 5
}