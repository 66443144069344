import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/pipes/shared.module';
import { InspectMeetingPopupComponent } from './inspect-meeting-popup.component';



@NgModule({
  declarations: [InspectMeetingPopupComponent],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
  ],
  exports: [InspectMeetingPopupComponent],
})
export class InspectMeetingPopupModule { }
