import { Component } from '@angular/core';
import { CallService } from './services/call.socket.service';
import { PresenceService } from './services/presence.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public presenceService: PresenceService,
    public themeService: ThemeService
  ) { }
}
