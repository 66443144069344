import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionInteraction } from '../action-interaction.base';
import { ActionInteractionLook } from '../IActionInteraction';

@Component({
  selector: 'app-action-input',
  templateUrl: './action-input.component.html',
  styleUrls: ['./action-input.component.scss', '../action-interaction.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionInputComponent extends ActionInteraction {

  @ViewChild('input') inputElement: ElementRef;

  @Input() look: ActionInteractionLook = ActionInteractionLook.Flat;
  @Input() loading = false;

  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  @Input() valid: boolean = false;
  @Output() validChange = new EventEmitter<boolean>();

  @Output() action = new EventEmitter<any>();

  // Input Properties
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() pattern: string = '';
  @Input() title: string = '';

  constructor() {
    super();
  }

  ngOnInit() { }

  public isValid() {
    return this.inputElement.nativeElement.checkValidity();
  }

  onChange() {
    this.valid = this.isValid();
    this.validChange.emit(this.valid);
    this.valueChange.emit(this.value);

    if (!this.valid && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.reportValidity();
    }
  }

  onAction() {
    this.onChange();
    if (this.valid) {
      this.action.emit(this.value);
    }
  }

}
