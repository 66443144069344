import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { IPresenceUser } from "../interfaces/IPresence";
import { IUser } from "../interfaces/IUser";
import { NavigationService } from "./navigation.service";
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from "./auth.service";
import { CallService } from "./call.socket.service";

@Injectable({
    providedIn: 'root'
})
export class PresenceService {

    private workspaceUsers = new BehaviorSubject<IPresenceUser[]>([]);

    private watchingUsers: {
        [key: string]: {
            details: IPresenceUser;
            subscription: Subscription;
        }
    } = {};

    private currentMeeting = '';
    private currentWorkspace = '';
    private currentTopic = '';

    constructor(
        private navigationService: NavigationService,
        private afDatabase: AngularFireDatabase,
        private callService: CallService
    ) {
        this.watchWorkspaces();
        this.watchTopics();
        this.watchCalls();
    }

    private watchWorkspaces() {
        this.navigationService.listenForWorkspaces().subscribe((e) => {
            if (e && e.current) {
                const usersToWatch = [...(e.current.users || []), ...(e.current.guests || [])];

                this.watchUserPresenceUpdates(usersToWatch);
                this.currentWorkspace = e.current.uid;
            } else {
                this.currentWorkspace = null;
            }
            this.updatePresence();
        });
    }

    private watchTopics() {
        this.navigationService.listenForTopics().subscribe((e) => {
            if (e && e.current) {
                this.currentTopic = e.current.uid;
            } else {
                this.currentTopic = null;
            }
            this.updatePresence();
        });
    }

    private watchCalls() {
        this.callService.getCallDetails().subscribe((e) => {
            this.currentMeeting = e ? e.id : null;
            this.updatePresence();
        })
    }

    /**
     * @description Watchs user presence updates for given users
     */
    private watchUserPresenceUpdates(users: string[]) {
        // Watch for user detail updates
        users.forEach(user => {
            if (!this.watchingUsers[user]) {
                this.watchingUsers[user] = {
                    details: null,
                    subscription: null
                };
            }

            this.watchingUsers[user].subscription = this.afDatabase.object<IPresenceUser>(`users/${user}`).valueChanges().subscribe((e) => {
                this.watchingUsers[user].details = e;
                this.onUserUpdated();
            })
        });

        // Clean out any users we are no longer interested in.
        Object.keys(this.watchingUsers).forEach(user => {
            if (!users.includes(user)) {
                this.watchingUsers[user].subscription.unsubscribe();
                delete this.watchingUsers[user];
            }
        });
    }

    /**
     * @description Called when a users presence has been updated.
     */
    private onUserUpdated() {
        this.workspaceUsers.next(
            Object.values(this.watchingUsers).map((x) => x.details).filter((x) => x != null)
        );
    }

    /**
     * @description Gets users presence updates for this workspace
     */
    public getUsersPresence() {
        return this.workspaceUsers.asObservable();
    }

    /**
     * @description Updates our current presence
     */
    private updatePresence() {
        if (!AuthService.auth) return;

        this.afDatabase.object<IPresenceUser>(`users/${AuthService.auth.uid}`).update({
            uid: AuthService.auth.uid,
            workspace: this.currentWorkspace,
            topic: this.currentTopic,
            meeting: this.currentMeeting,
            online: true
        });

        const ref = this.afDatabase.database.ref(`users/${AuthService.auth.uid}`);
        ref.onDisconnect().update({
            workspace: null,
            topic: null,
            meeting: null,
            online: false
        });
    }

}