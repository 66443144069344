import { IWorkspaceRole } from "../interfaces/IWorkspace";

export const WorkspaceRoleOwner: IWorkspaceRole = {
    uid: null,
    name: 'Owner',
    permissions: {
        workspace_manage: true,
        roles_manage: true,
        users_manage: true,
        topics_manage: true,
        meetings_manage: true,
        tasks_manage: true,
        files_manage: true,
        analytics_manage: true
    }
}

export const WorkspaceRoleDefault: IWorkspaceRole = {
    uid: null,
    name: 'Team Member',
    permissions: {
        analytics_view: true,
        meetings_view: true,
        tasks_manage: true,
        files_manage: true,
    }
}