import { ITopic } from "./ITopic";
import { IWorkspace } from "./IWorkspace";

export interface INavigationWorkspaces {
    current: IWorkspace;
    all: IWorkspace[];
}

export interface INavigationTopics {
    current: ITopic;
    all: ITopic[];
}

export enum NavigationPageType {
    Search = 'search',
    Board = 'board',
    Content = 'content',
    Meetings = 'meetings',
    Tasks = 'tasks',
    Settings = 'settings',
    Analytics = 'analytics',
    Call = 'call'
}

export interface INavigationPage {
    icon?: string;
    label: string;
    type: NavigationPageType;
    component?: any;
    permission?: string;
}