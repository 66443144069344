import { Injectable } from "@angular/core";
// import { ActionSheetOptions } from "@capacitor/core";
import { ActionSheetController, AlertController, NavController, PopoverController } from "@ionic/angular";
import { InspectMeetingPopupComponent } from "../components/popups/inspect-meeting/inspect-meeting-popup.component";
import { InspectUserPopupComponent } from "../components/popups/inspect-user/inspect-user-popup.component";
import { SimpleListPopupComponent } from "../components/popups/simple-list/simple-list-popup.component";
import { SimpleTextPopupComponent } from "../components/popups/simple-text/simple-text-popup.component";
import { IHoverLinkOption } from "../components/simple/hover-link/hover-link.component";
import { IMeeting } from "../interfaces/IMeeting";
import { IUser } from "../interfaces/IUser";
import { IWorkspace } from "../interfaces/IWorkspace";
import { AuthService } from "./auth.service";
import { LogService } from "./log.service";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})
export class InteractionService {

    constructor(
        private userService: UserService,
        private popoverController: PopoverController,
        private authService: AuthService,
        private navController: NavController,
        private logService: LogService,
        private alertController: AlertController
    ) { }

    /**
     * @description Inspects user
     * @param user 
     */
    public async inspectUser(user: IUser | string, ev: MouseEvent, settings?: boolean) {
        if (typeof user === 'string') {
            user = await this.userService.getUserDetails(user, true);
        } else {
            user = await this.userService.getUserDetails(user.uid, true);
        }

        const popover = await this.popoverController.create({
            component: InspectUserPopupComponent,
            event: ev,
            translucent: false,
            componentProps: {
                user,
                settings
            }
        });
        await popover.present();
        const { role } = await popover.onWillDismiss();

        if (role === 'logout') {
            this.authService.signout().then(() => this.navController.navigateBack('/'))
        } else {
            this.logService.log({
                label: 'Interaction Service',
                message: 'Pressed',
                args: [role]
            });
        }
    }

    /**
     * @description Prompts input text from user
     */
    public async promptInputText(options: { header?: string, placeholder?: string, button?: string, inputOptions?: any }, ev: MouseEvent) {
        const popover = await this.popoverController.create({
            component: SimpleTextPopupComponent,
            event: ev,
            translucent: false,
            componentProps: {
                header: options.header,
                placeholder: options.placeholder,
                button: options.button,
                inputOptions: {
                    autocapitalize: 'on',
                    autocorrect: 'on',
                    ...options.inputOptions
                }
            }
        });
        await popover.present();
        const { data } = await popover.onWillDismiss();
        return data as string;
    }

    /**
     * @description Shows a popup list of simple options with callbacks.
     */
    public async promptSimpleList(options: { header?: string, options: IHoverLinkOption[] }, ev: MouseEvent) {
        const popover = await this.popoverController.create({
            component: SimpleListPopupComponent,
            event: ev,
            translucent: false,
            componentProps: {
                header: options.header,
                options: options.options
            }
        });
        await popover.present();
        const { data } = await popover.onWillDismiss();
        return data as string;
    }

    /**
     * @description Shows a quick confirmation dialoge and returns the result
     * @returns  
     */
    public async showConfirmation(header: string, message: string, buttons: { key: string, label: string }[]) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: buttons.map((x) => {
                return {
                    text: x.label,
                    role: x.key
                }
            })
        });

        await alert.present();
        const { role } = await alert.onWillDismiss();
        return role;
    }

}