import { Directive, ElementRef } from '@angular/core';
@Directive({
    selector: '[customScrollbar]'
})
export class CustomScrollbarDirective {
    constructor(el: ElementRef) {
        const stylesheet = `
    /* width */
    ::-webkit-scrollbar {
        width: .3rem;
        height: 4px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(var(--theme-background-contrast), 0.1);
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(var(--theme-background-contrast), 0.2);
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(var(--theme-background-contrast), 0.5);
    }
    `;

        const styleElement = el.nativeElement.shadowRoot.querySelector('style');

        if (styleElement) {
            styleElement.append(stylesheet);
        } else {
            const barStyle = document.createElement('style');
            barStyle.append(stylesheet);
            el.nativeElement.shadowRoot.appendChild(barStyle);
        }

    }
}