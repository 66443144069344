import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";
import { AngularFireStorage } from "@angular/fire/storage";
import firebase from 'firebase/app';
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { WorkspaceRoleDefault, WorkspaceRoleOwner } from "../constants/default-roles.const";
import { AnalyticLocation, AnalyticTag, AnalyticType } from "../interfaces/IAnalytics";
import { IChatMessage } from "../interfaces/IChat";
import { IContent } from "../interfaces/IContent";
import { IMeeting, IMeetingDetails } from "../interfaces/IMeeting";
import { PrivacyMode } from "../interfaces/IPrivacy";
import { ITopic } from "../interfaces/ITopic";
import { IWorkspace } from "../interfaces/IWorkspace";
import { UtilityHelper } from "../utils/utility-helper.util";
import { AnalyticsService } from "./analytics.service";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class WorkspaceServiceFB {

    public MESSAGE_PAGE_LENGTH = 20;

    private workspacesSub = new BehaviorSubject<IWorkspace[]>([])
    private $workspacesUnsubscribe: () => void;

    private topicsSub = new BehaviorSubject<ITopic[]>([])
    private $topicsUnsubscribe: () => void;

    private meetingsSub = new BehaviorSubject<IMeeting[]>([])
    private $meetingsUnsubscribe: () => void;

    private contentSub = new BehaviorSubject<IContent[]>([])
    private $contentUnsubscribe: () => void;

    constructor(
        private authService: AuthService,
        private afStore: AngularFirestore,
        private analyticsService: AnalyticsService,
        private afFunctions: AngularFireFunctions,
        private afStorage: AngularFireStorage,
    ) { this.listenForAuthUpdates(); }

    /**
     * @description Listens for auth updates
     */
    private listenForAuthUpdates() {
        this.authService.listenForAuthState().subscribe(async (res) => {
            if (this.$workspacesUnsubscribe) { this.$workspacesUnsubscribe(); }
            if (res) {
                this.listenForWorkspaceChanges();
            }
        });
    }

    /**
     * @description Listens for workspaces that a user is within.
     */
    private listenForWorkspaceChanges() {
        this.$workspacesUnsubscribe = this.afStore.collection(`workspaces`).ref
            .where('users', 'array-contains', AuthService.auth.uid).onSnapshot((e) => {
                this.workspacesSub.next(e.docs.map((x) => (x.data() as IWorkspace)));
            });
    }

    /**
     * @description Listen to the workspaces we have access to.
     */
    public getWorkspaces() {
        return this.workspacesSub.asObservable();
    }

    /**
     * @description Listens for workspaces that a user is within.
     */
    public listenForTopicsChanges(workspaceUID: string) {
        if (this.$topicsUnsubscribe) { this.$topicsUnsubscribe(); }
        this.topicsSub.next([]);
        this.$topicsUnsubscribe = this.afStore.collection(`workspaces/${workspaceUID}/topics`).ref
            .onSnapshot((e) => {
                this.topicsSub.next(e.docs.map((x) => (x.data() as ITopic)));
            });
    }

    /**
     * @description Listen to the topics in the workspace that we have access to
     */
    public getTopics() {
        return this.topicsSub.asObservable();
    }

    public listenForContentChanges(workspaceUID: string) {
        if (this.$contentUnsubscribe) { this.$contentUnsubscribe(); }
        this.contentSub.next([]);
        this.$contentUnsubscribe = this.afStore.collection(`workspaces/${workspaceUID}/content`).ref
            .onSnapshot((e) => {
                this.contentSub.next(e.docs.map((x) => (x.data() as IContent)));
            });
    }

    public async setContent(workspace: string, data: IContent) {
        if (!data.uid) data.uid = this.afStore.createId();
        await this.afStore.doc(`workspaces/${workspace}/content/${data.uid}`).set(data, { merge: true });
        return data.uid;
    }

    public deleteContent(workspace: string, data: IContent) {
        return this.afStore.doc(`workspaces/${workspace}/content/${data.uid}`).delete();
    }

    /**
     * @description Listens for meetings that a user has access to in this workspace
     */
    public listenForMeetingChanges(workspaceUID: string) {
        if (this.$meetingsUnsubscribe) { this.$meetingsUnsubscribe(); }
        this.meetingsSub.next([]);
        this.$meetingsUnsubscribe = this.afStore.collection(`workspaces/${workspaceUID}/meetings`).ref
            .onSnapshot((e) => {
                this.meetingsSub.next(e.docs.map((x) => (x.data() as IMeeting)));
            });
    }

    public getMeetingDetails(workspace: string, meeting: string, secret: string) {
        return new Promise<IMeetingDetails>(async (res) => {
            this.afFunctions.httpsCallable('getMeetingDetails')({
                meeting,
                workspace,
                secret: secret
            }).subscribe((e: IMeetingDetails) => res(e));
        });
    }

    public registerMeetingGuest(workspace: string, meeting: string, secret: string, uid: string) {
        return new Promise<any>(async (res) => {
            this.afFunctions.httpsCallable('registerMeetingGuest')({
                meeting,
                workspace,
                secret: secret,
                guestUid: uid
            }).subscribe((e: any) => res(e));
        });
    }

    public getCheckoutSession(uid: string, email: string) {
        // TODO: Pass in subscription tier wanted.
        return new Promise<string>(async (res) => {
            this.afFunctions.httpsCallable('createCheckoutSession')({
                uid, email,
                production: environment.production,
                beta: location.hostname.startsWith(environment.devPrefix)
            }).subscribe((e: string) => res(e));
        });
    }

    /**
     * @description Listen for meeting details and updates
     */
    public getMeetingListener(workspace: string, meeting: string) {
        return this.afStore.doc<IMeeting>(`workspaces/${workspace}/meetings/${meeting}`).valueChanges();
    }

    /**
     * @description Listen to the meetings available in the current workspace
     */
    public getMeetings() {
        return this.meetingsSub.asObservable();
    }

    public getContent() {
        return this.contentSub.asObservable();
    }

    /**
     * @description Creates a workspace
     */
    public createWorkspace(name: string) {
        return new Promise<string>((res) => {
            const ownerUID = this.afStore.createId();
            const defaultUID = this.afStore.createId();

            const workspace: IWorkspace = {
                uid: this.afStore.createId(),
                slug: UtilityHelper.RandomSlug(6),
                name,
                photo: null,
                roles: {
                    all: [{
                        ...WorkspaceRoleOwner,
                        uid: ownerUID
                    }, {
                        ...WorkspaceRoleDefault,
                        uid: defaultUID
                    }],
                    assigned: {
                        [AuthService.auth.uid]: ownerUID
                    }
                },
                users: [AuthService.auth.uid]
            };

            this.afStore.doc(`workspaces/${workspace.uid}`).set(workspace)
                .then((e) => {
                    this.analyticsService.add(workspace.uid, AnalyticLocation.Management, {
                        user: AuthService.auth.uid,
                        type: AnalyticType.Create,
                        tags: [AnalyticTag.Workspace],
                    });
                    res(workspace.uid)
                })
                .catch(() => res(''));
        })
    }

    /**
     * @description Deletes workspace
     */
    public deleteWorkspace(workspace: IWorkspace) {
        return new Promise<boolean>((res) => {
            if (!this.hasPermission('workspace_manage', workspace)) res(false);

            this.afStore.doc(`workspace/${workspace}`).delete()
                .then(() => res(true))
                .catch(() => res(false));
        })
    }

    /**
     * @description Gets a users role within a workspace 
     */
    getUserRole(workspace: IWorkspace, uid?: string) {
        if (!uid) uid = AuthService.auth.uid;
        const ourRoleKey = (workspace.roles?.assigned || {})[uid];
        if (ourRoleKey) {
            return workspace.roles.all.find((x) => x.uid === ourRoleKey);
        } else {
            return workspace.users.findIndex((x) => x == uid) === 0 ? WorkspaceRoleOwner : WorkspaceRoleDefault;
        }
    }

    /**
     * @description Returns true if a user has a permission for a given workspace.
     */
    hasPermission(permission: string, workspace: IWorkspace, uid?: string): boolean {
        return this.getUserRole(workspace, uid).permissions[permission];
    }

    /**
     * @description Creates a topic
     * @returns  
     */
    public createTopic(name: string, order: number, workspace: string) {
        return new Promise<string>((res) => {
            const topicUID = this.afStore.createId();

            const topic: ITopic = {
                uid: topicUID,
                slug: UtilityHelper.RandomSlug(6),
                name,
                photo: null,
                order,
                meta: {
                    snippet: {
                        user: AuthService.auth.uid,
                        text: 'Topic Created'
                    },
                    last_update: firebase.firestore.FieldValue.serverTimestamp() as any
                }
            };

            this.afStore.doc(`workspaces/${workspace}/topics/${topicUID}`).set(topic)
                .then((e) => {
                    this.analyticsService.add(workspace, AnalyticLocation.Management, {
                        user: AuthService.auth.uid,
                        type: AnalyticType.Create,
                        tags: [AnalyticTag.Topic],
                    });
                    res(topicUID);
                })
                .catch(() => res(''));
        })
    }

    public updateTopic(workspace: string, topic: ITopic) {
        return new Promise<boolean>((res) => {
            this.afStore.doc(`workspaces/${workspace}/topics/${topic.uid}`).set(topic, { merge: true })
                .then((e) => {
                    this.analyticsService.add(workspace, AnalyticLocation.Management, {
                        user: AuthService.auth.uid,
                        type: AnalyticType.Update,
                        tags: [AnalyticTag.Topic],
                    });
                    res(true)
                })
                .catch(() => res(false));
        });
    }

    public updateTopics(workspace: string, topics: ITopic[]) {
        const batchItems = [];
        for (const topic of topics) {
            batchItems.push({
                location: `workspaces/${workspace}/topics/${topic.uid}`,
                data: topic,
                sendType: 'update',
                docType: 'doc',
            });
        }
        this.analyticsService.add(workspace, AnalyticLocation.Management, {
            user: AuthService.auth.uid,
            type: AnalyticType.Update,
            tags: [AnalyticTag.Workspace, AnalyticTag.Topic],
        });
        return this.batchUpdate(batchItems);
    }

    /**
     * @description Create topic
     */
    public async workspace_create(name: string, templateID: string) {
        return await this.afFunctions.httpsCallable('workspace_create')({
            workspace_name: name,
            workspace_template: templateID,
        }).toPromise();
    }

    /**
     * @description Create topic
     */
    public async topic_create(workspace: string, name: string, order: number) {
        return await this.afFunctions.httpsCallable('topic_create')({
            workspace: workspace,
            topic_name: name,
            topic_order: order
        }).toPromise();
    }

    /**
     * @description Deletes topic
     */
    public async topic_delete(workspace: IWorkspace, topic: string) {
        return await this.afFunctions.httpsCallable('topic_delete')({
            workspace: workspace.uid,
            topic
        }).toPromise();
    }

    private batchUpdate(batchItems: {
        location: string, data?: any,
        sendType: 'update' | 'set' | 'delete',
        setOptions?: firebase.firestore.SetOptions,
        docType: 'col' | 'doc',
    }[]) {
        const batch = this.afStore.firestore.batch();
        for (const item of batchItems) {
            let ref = null;
            if (item.docType === 'doc') { ref = this.afStore.doc(item.location).ref; }
            if (item.docType === 'col') { ref = this.afStore.collection(item.location).ref; }
            switch (item.sendType) {
                case 'update':
                    batch.update(ref, item.data);
                    break;
                case 'set':
                    batch.set(ref, item.data, item.setOptions);
                    break;
                case 'delete':
                    batch.delete(ref);
                    break;
            }
        }
        return batch.commit();
    }

    /**
     * @description Creates a meeting room
     * @returns  
     */
    public createMeeting(name: string, privacy: PrivacyMode, workspace: string, topic: string, order: number) {
        return new Promise<string>((res) => {
            const uid = this.afStore.createId();

            const meeting: IMeeting = {
                uid,
                name,
                privacy,
                slug: UtilityHelper.RandomSlug(6),
                order,
                topic
            };

            this.afStore.doc(`workspaces/${workspace}/meetings/${uid}`).set(meeting)
                .then((e) => {
                    this.analyticsService.add(workspace, AnalyticLocation.People, {
                        user: AuthService.auth.uid,
                        type: AnalyticType.Create,
                        tags: [AnalyticTag.Meeting],
                        message: name
                    });
                    res(uid);
                })
                .catch(() => res(''));
        });
    }

    public updateMeetings(workspace: string, meetings: IMeeting[]) {
        const batchItems = [];
        for (const meeting of meetings) {
            batchItems.push({
                location: `workspaces/${workspace}/meetings/${meeting.uid}`,
                data: meeting,
                sendType: 'update',
                docType: 'doc',
            });
        }
        this.analyticsService.add(workspace, AnalyticLocation.Management, {
            user: AuthService.auth.uid,
            type: AnalyticType.Update,
            tags: [AnalyticTag.Meeting],
        });
        return this.batchUpdate(batchItems);
    }

    /**
     * @description Shares a meeting and returns the secret for joining.
     * @param workspace 
     * @param meeting 
     * @returns  Secret for joining a meeting
     */
    public shareMeeting(workspace: string, meeting: IMeeting) {
        return new Promise<string>(async (res) => {
            const sharedDetails = meeting.shared || {
                date: firebase.firestore.Timestamp.now(),
                secret: UtilityHelper.RandomSlug(6)
            };

            if (!meeting.shared) {
                try {
                    await this.afStore.doc(`workspaces/${workspace}/meetings/${meeting.uid}`).set({
                        shared: sharedDetails
                    }, { merge: true });
                }
                catch {
                    res(null)
                }
            }

            res(sharedDetails.secret);
        });
    }

    /**
     * @description Stops sharing meeting
     */
    public stopSharingMeeting(workspace: string, meeting: IMeeting) {
        return new Promise<void>(async (res) => {
            if (meeting.shared) {
                try {
                    await this.afStore.doc(`workspaces/${workspace}/meetings/${meeting.uid}`).set({
                        shared: null
                    }, { merge: true });
                }
                catch {
                    res()
                }
            }

            res();
        });
    }

    /**
     * @description Deletes a meeting
     * @param meeting Meeting UID
     * @param workspace Workspace UID
     * @returns  
     */
    public deleteMeeting(meeting: IMeeting, workspace: string) {
        return new Promise<boolean>((res) => {
            this.afStore.doc(`workspaces/${workspace}/meetings/${meeting.uid}`).delete()
                .then((e) => {
                    this.analyticsService.add(workspace, AnalyticLocation.People, {
                        user: AuthService.auth.uid,
                        type: AnalyticType.Delete,
                        tags: [AnalyticTag.Meeting],
                        message: meeting.name
                    });
                    res(true);
                })
                .catch(() => res(false));
        });
    }

    /**
     * @description Send a message to a topic
     * @returns  
     */
    public async sendMessage(message: IChatMessage, workspace: string, topic: string) {
        try {
            const uid = this.afStore.createId();
            message.uid = uid;
            await this.afStore.doc(`workspaces/${workspace}/topics/${topic}/chats/${uid}`).set(message);
            await this.updateTopic(workspace, {
                uid: topic,
                meta: {
                    snippet: {
                        user: AuthService.auth.uid,
                        text: UtilityHelper.StripHTML(message.message).split(' ').slice(0, 5).join(' ')
                    },
                    last_update: firebase.firestore.FieldValue.serverTimestamp() as any
                }
            })
            return true;
        } catch {
            return false;
        }
    }

    public deleteMessage(message: IChatMessage, workspace: string, topic: string) {
        return this.afStore.doc(`workspaces/${workspace}/topics/${topic}/chats/${message.uid}`).delete();
    }

    public updateMessage(message: IChatMessage, workspace: string, topic: string) {
        return this.afStore.doc(`workspaces/${workspace}/topics/${topic}/chats/${message.uid}`).set(message, { merge: true });
    }

    public listenForMessages(workspace: string, topic: string) {
        return this.afStore.collection(`workspaces/${workspace}/topics/${topic}/chats`).ref
            .orderBy('created', 'asc').limitToLast(this.MESSAGE_PAGE_LENGTH);
    }

    public lastMessages(workspace: string, topic: string) {
        return new Promise<IChatMessage[]>((res) => {
            this.afStore.collection<IChatMessage>(`workspaces/${workspace}/topics/${topic}/chats`).ref
                .orderBy('created', 'asc').limitToLast(this.MESSAGE_PAGE_LENGTH).get()
                .then((e) => {
                    res(e.docs.map((x) => x.data()))
                })
        })
    }

    public historicMessages(workspace: string, topic: string, index: any) {
        return new Promise<IChatMessage[]>((res) => {
            this.afStore.collection<IChatMessage>(`workspaces/${workspace}/topics/${topic}/chats`).ref
                .orderBy('created', 'desc').startAfter(index).limit(this.MESSAGE_PAGE_LENGTH).get()
                .then((e) => {
                    res(e.docs.map((x) => x.data()))
                })
        })
    }

    public uploadFile(workspace: string, item: string, data: any) {
        return this.afStorage.upload(`workspaces/${workspace}/content/${item}/${this.afStore.createId()}`, data);
    }
}