import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import firebase from 'firebase/app';
import { Subscription } from 'rxjs';
import { IContent, IContentFile } from 'src/app/interfaces/IContent';
import { ContentService } from 'src/app/services/content.service';
import { DisplayInfoService } from 'src/app/services/display-info.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { LogService } from 'src/app/services/log.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { MediaHelper } from 'src/app/utils/media-helper.util';
import { SwiperOptions } from 'swiper';
import { IDropdownOption } from '../../simple/action-interaction/action-dropdown/action-dropdown.component';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss'],
})
export class ContentModalComponent implements OnInit, OnDestroy {

  slideOptions: SwiperOptions = {
    autoHeight: true,
    autoplay: false,
    allowTouchMove: false
  }

  @Input() parent: string;
  @Input() content: IContent;
  @Input() contentUID: string;
  @Input() contentUpload: File;

  isLoading = false;
  dragging = false;

  editing = false;
  contentSub: Subscription;
  uploading = -1;

  creatingName = ''
  creatingType: IDropdownOption;
  options: IDropdownOption[] = [
    { icon: 'document', label: 'Item', data: 'single' },
    { icon: 'folder-open', label: 'Folder', data: 'folder' },
  ];
  childDetails: { [key: string]: IContent } = {};

  typeOptionsSelected: IDropdownOption;
  typeOptions: IDropdownOption[] = [
    { icon: 'apps', label: 'All', data: '*' },
    { icon: 'headset', label: 'Audio', data: 'audio/*' },
    { icon: 'image', label: 'Image', data: 'image/*' },
    { icon: 'videocam', label: 'Video', data: 'video/*' },
    // { icon: 'document-attach', label: 'Custom', data: 'custom/' },
  ];

  constructor(
    private contentService: ContentService,
    private modalController: ModalController,
    private interactionService: InteractionService,
    private displayInfoService: DisplayInfoService,
    private navService: NavigationService
  ) { }

  async ngOnInit() {
    this.editing = !!this.content;

    if (this.content) {
      await this.watchContentUpdates(this.content.uid);
    } else if (this.contentUID) {
      this.editing = true;
      await this.watchContentUpdates(this.contentUID);
    }

    if (this.contentUpload) {
      await this.uploadFile(this.contentUpload);
      this.contentUpload = null;
    } else {
      this.typeOptionsSelected = this.content?.accept ? this.typeOptions.find((x) => x.data === this.content.accept) : this.typeOptions[0];
      this.navService.setWorkspacePage(null, { view: this.content.uid });
    }
  }

  ngOnDestroy() {
    if (this.contentSub) { this.contentSub.unsubscribe(); }
    this.navService.setWorkspacePage(null, { view: null });
  }

  watchContentUpdates(uid: string) {
    return new Promise<void>((res) => {
      if (this.contentSub) { this.contentSub.unsubscribe(); }
      this.contentSub = this.contentService.getContentListener().subscribe((e) => {
        this.content = e.find((x) => x.uid === uid);

        if (this.content?.type === 'folder') {
          (this.content.children || []).forEach((e) => this.childDetails[e] = this.contentService.content.value.find((x) => x.uid === e))
        }

        if (e != null) res();
      });
    });
  }

  async searchForFile() {
    const selectedFiles = await MediaHelper.GetLocalFile({ multiple: false, allowedTypes: [this.content?.accept || '*'] });
    if (selectedFiles.length === 0) return;
    selectedFiles.forEach(file => this.uploadFile(file));
  }

  async uploadFile(file: File) {
    if (!this.content) {
      return;
    };

    this.contentService.uploadFileInto(file, this.content, (e) => {
      this.uploading = e.percent;
    })
      .then((e) => this.updateContent(e))
      .finally(() => this.uploading = -1);
  }

  async updateContent(details: IContentFile) {
    this.contentService.setContent({
      uid: this.content.uid,
      size: details.size,
      versions: [
        details,
        ...(this.content.versions || [])
      ]
    } as any).then((e) => {
      console.log('Updated File');
    }).catch((e) => {
      console.log('oh no', e);
    })
  }

  createNew(type) {
    this.creatingType = this.options.find((x) => x.data === type);
  }

  async save() {
    if (this.content.name.length < 3) {
      return;
    }

    this.isLoading = true;
    await this.contentService.setContent({
      uid: this.content.uid,
      name: this.content.name,
      accept: this.typeOptionsSelected.data
    } as any);
    this.isLoading = false;
  }

  async doneOrCreate() {
    if (this.creatingType.data === 'folder') {
      const uid = await this.contentService.setContent({
        name: this.creatingName,
        type: this.creatingType.data,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        parent: this.parent,
      } as any);
      this.modalController.dismiss();
    } else {
      const selectedFiles = await MediaHelper.GetLocalFile({ multiple: false });
      if (selectedFiles.length === 0) return;
      const uid = await this.contentService.setContent({
        name: this.creatingName,
        type: this.creatingType.data,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        parent: this.parent,
      } as any);

      await this.watchContentUpdates(uid);
      this.editing = true;
      selectedFiles.forEach(file => this.uploadFile(file));
    }
  }

  download(item: IContentFile) {
    window.open(item.link, '_blank')
  }

  async deleteHistory(item: IContentFile) {
    this.isLoading = true;
    const index = this.content.versions.findIndex((x) => x == item);
    const newArray = this.content.versions;
    newArray.splice(index, 1);

    await this.contentService.setContent({
      uid: this.content.uid,
      size: newArray.length > 0 ? newArray[0].size : 0,
      versions: newArray,
    } as any)
    await this.contentService.deleteFile(item);
    this.isLoading = false;
  }

  async delete(item?: IContent) {
    if (!item) item = this.content;

    if (item.type === 'folder' && this.contentService.calculateItemSize(item).items > 0) {
      this.displayInfoService.showToast('You can only delete empty folders.');
      return;
    }

    const result = await this.interactionService.showConfirmation(
      `Delete ${item.type === 'folder' ? 'Folder' : 'Item'}`,
      `Are you sure you want to delete "${item.name}"?`, [
      {
        key: 'cancel',
        label: 'Cancel'
      },
      {
        key: 'delete',
        label: 'Delete'
      }
    ]);

    if (result === 'delete') {
      this.modalController.dismiss();
      const name = item.name;

      this.contentService.deleteContent(item)
        .finally(() => {
          this.displayInfoService.showToast(name + ' Deleted.');
        });
    }
  }

  historyItemMore(item: IContentFile, ev: MouseEvent) {
    this.interactionService.promptSimpleList({
      options: [
        {
          label: 'Download',
          icon: 'cloud-download',
          callback: () => this.download(item)
        },
        {
          label: 'Delete',
          icon: 'close',
          callback: () => this.deleteHistory(item)
        }
      ]
    }, ev);
  }

  childItemMore(item: string, ev: MouseEvent) {
    const details = this.childDetails[item];
    if (!details) return;

    this.interactionService.promptSimpleList({
      options: [
        {
          label: 'Delete',
          icon: 'close',
          callback: () => this.delete(details)
        }
      ]
    }, ev);
  }

  draggingOver(ev: DragEvent) {
    this.dragging = ev && ev.dataTransfer?.items?.length === 1;

    if (!ev) return;
    ev.preventDefault();
  }

  fileDropped(ev: DragEvent) {
    if (this.content.type !== 'single') return;
    ev.preventDefault();

    if (ev.dataTransfer.items.length !== 1) {
      return;
    }

    this.uploadFile(ev.dataTransfer.items[0].getAsFile());
    this.dragging = false;
  }
}
