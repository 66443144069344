import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-name',
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserNameComponent implements OnInit {

  @Input() name = 'User Name';
  @Input() suffix = '';

  constructor() { }

  ngOnInit() { }

}
