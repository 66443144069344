import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/pipes/shared.module';
import { InspectUserPopupComponent } from './inspect-user-popup.component';



@NgModule({
  declarations: [InspectUserPopupComponent],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
  ],
  exports: [InspectUserPopupComponent],
})
export class InspectUserPopupModule { }
