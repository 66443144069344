import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NavController } from "@ionic/angular";
import firebase from 'firebase/app';
import * as moment from "moment";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { take, first } from 'rxjs/operators';
import { AnalyticLocation, IAnalytic, IAnalyticReport } from "../interfaces/IAnalytics";
import { NavigationPageType } from "../interfaces/INavigation";
import { IUser, IUserCached, IUserFriendsList, UserFriendStatus } from "../interfaces/IUser";
import { UtilityHelper } from "../utils/utility-helper.util";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(
        private afStore: AngularFirestore
    ) { }

    /**
     * @description Add a new entry into the analytics for the workspace
     */
    public add(workspace: string, location: AnalyticLocation, data: IAnalyticReport) {
        return new Promise((res) => {
            this.afStore.doc(`workspaces/${workspace}/analytics/_overview`).set({
                [location]: {
                    total: firebase.firestore.FieldValue.increment(1),
                    [data.user]: {
                        count: firebase.firestore.FieldValue.increment(1),
                        last: firebase.firestore.Timestamp.now()
                    }
                }
            }, { merge: true })
                .then(() => {
                    this.afStore.doc<IAnalyticReport>(`workspaces/${workspace}/analytics/${location}/reports/${this.afStore.createId()}`).set({
                        ...data,
                        date: firebase.firestore.Timestamp.now()
                    });
                })
        });
    }
}