import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ellipsis'
})

export class EllipsisPipe implements PipeTransform {

    constructor() { }

    transform(value: string, maxLength: number): any {
        if (value.length > maxLength) {
            return value.slice(0, maxLength) + '...';
        } else {
            return value;
        }
    }
}
