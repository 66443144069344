import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PreferencesService } from 'src/app/services/preferences.service';
import { MediaHelper } from 'src/app/utils/media-helper.util';
import { UtilityHelper } from 'src/app/utils/utility-helper.util';
import { IDropdownOption } from '../../simple/action-interaction/action-dropdown/action-dropdown.component';

@Component({
  selector: 'app-join-call-modal',
  templateUrl: './join-call-modal.component.html',
  styleUrls: ['./join-call-modal.component.scss'],
})
export class JoinCallModalComponent implements OnInit {

  @Input() meeting = '';

  videoDevices: IDropdownOption[] = [];
  audioDevices: IDropdownOption[] = [];
  availability;

  selectedAudio: IDropdownOption;
  selectedVideo: IDropdownOption;
  videoOn = false;
  audioOn = true;

  loading = false;

  previewSource: MediaStream;

  getNewStream = new Subject();

  askForPermission = false;

  constructor(
    private modalController: ModalController,
    private prefsService: PreferencesService,
    private alertController: AlertController
  ) { }

  async ngOnInit() {
    this.askForDevices();
  }

  private async checkDevices() {
    const devicesAvailable = await MediaHelper.GetAvailableDevices();

    if (devicesAvailable.audio_in && devicesAvailable.video_in) {
      return true;
    }

    if (!devicesAvailable.audio_in || !devicesAvailable.video_in) {
      this.previewSource = await MediaHelper.GetMediaStream(true, true, 'default', 'default');
    }

    const alert = await this.alertController.create({
      header: 'Allow Permissions',
      message: `Please allow Knapsack to access the ${devicesAvailable.audio_in ? 'Camera' : 'Microphone'} to continue.`,
      buttons: [{
        text: 'Done'
      }],
      backdropDismiss: false
    });
    alert.present();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      if (stream) MediaHelper.DisposeStream(stream);
      await alert.onDidDismiss();
      return await this.checkDevices();
    }
    catch {
      return await this.checkDevices();
    }
  }

  private async askForDevices() {
    const gotPermission = await this.checkDevices();
    if (!gotPermission) {
      return;
    };

    setTimeout(async () => {
      this.availability = await MediaHelper.GetAvailableDevices();
      const devices = await navigator.mediaDevices.enumerateDevices();

      this.audioDevices = this.filterDevices(devices.filter((x) => x.kind === 'audioinput'));
      this.videoDevices = this.filterDevices(devices.filter((x) => x.kind === 'videoinput'));

      this.videoDevices.unshift({
        label: 'Disabled',
        data: null,
      });

      await UtilityHelper.Wait(10);
      const prefs = await this.prefsService.getSnapshot();

      let defaultAudio = this.audioDevices.findIndex((x) => prefs?.devices?.audio ? x.data?.deviceId === prefs?.devices?.audio : x.data?.deviceId === 'default');
      let defaultVideo = this.videoDevices.findIndex((x) => prefs?.devices?.video ? x.data?.deviceId === prefs?.devices?.video : x.data?.deviceId === 'default');

      if (defaultAudio === -1) defaultAudio = 0;
      if (defaultVideo === -1) defaultVideo = 0;

      this.selectedAudio = this.audioDevices[defaultAudio];
      this.selectedVideo = this.videoDevices[defaultVideo];

      setTimeout(async () => {
        this.getNewStream.pipe(debounceTime(200)).subscribe(() => this.fetchNewStream());
        (await this.modalController.getTop()).onWillDismiss().then(() => {
          MediaHelper.DisposeStream(this.previewSource);
          this.previewSource = null;
        })

        this.devicesChanged();
      }, 50);
    }, 10);
  }

  private filterDevices(devices: MediaDeviceInfo[]) {
    const options: IDropdownOption[] = [];
    const removeSameGroup = false;
    const allowSameGroup = true;

    devices.forEach((device) => {
      if (allowSameGroup || !options.find((x) => x.data.groupId === device.groupId) || !removeSameGroup) {
        options.push({
          label: this.formatDeviceName(device),
          data: device
        });
      }
    });

    return options;
  }

  private formatDeviceName(s: MediaDeviceInfo) {
    let name = s.label;
    if (s.deviceId === 'default') {
      name = 'Default';
    } else {
      const lastIndex = name.lastIndexOf('(');
      name = name.substring(0, lastIndex === -1 ? name.length : lastIndex);
    }

    return name;
  }

  async fetchNewStream() {
    this.loading = true;
    const wantsAudio = !!this.selectedAudio?.data;
    const wantsVideo = !!this.selectedVideo?.data;

    MediaHelper.GetMediaStream(
      wantsAudio,
      wantsVideo,
      wantsAudio && this.selectedAudio.data.deviceId,
      wantsVideo && this.selectedVideo.data.deviceId).then((e) => {
        if (e) {
          this.videoOn = wantsVideo && e.getVideoTracks().length > 0;
          this.audioOn = wantsAudio && e.getAudioTracks().length > 0;
        }

        this.prefsService.update({
          devices: {
            audio: this.audioOn && this.selectedAudio.data.deviceId,
            video: this.videoOn && this.selectedVideo.data.deviceId
          }
        });

        this.previewSource = e;
        this.loading = false;
      });
  }

  devicesChanged() {
    this.getNewStream.next();
  }

  cancel() {
    this.modalController.dismiss();
  }

  async join() {
    this.modalController.dismiss({
      audio: this.audioOn && !!this.selectedAudio.data && this.selectedAudio.data.deviceId,
      video: this.videoOn && !!this.selectedVideo.data && this.selectedVideo.data.deviceId,
    });
  }

}
