import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/pipes/shared.module';
import { InspectContentPopupComponent } from './inspect-content-popup.component';



@NgModule({
  declarations: [InspectContentPopupComponent],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
  ],
  exports: [InspectContentPopupComponent],
})
export class InspectContentPopupModule { }
