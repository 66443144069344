import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { MediaHelper } from "../utils/media-helper.util";
import { AuthService } from "./auth.service";
import { SocketService } from "./socket.service";
import Peer from 'peerjs';
import { first } from "rxjs/operators";
import { AnalyticsService } from "./analytics.service";
import { NavigationService } from "./navigation.service";
import { AnalyticLocation, AnalyticTag, AnalyticType } from "../interfaces/IAnalytics";
import { LogService, LogType } from "./log.service";
import { UserService } from "./user.service";
import { IUser } from "../interfaces/IUser";
import { UtilityHelper } from "../utils/utility-helper.util";
import { IMeeting } from "../interfaces/IMeeting";
import { ModalController } from "@ionic/angular";
import { JoinCallModalComponent } from "../components/modals/join-call-modal/join-call-modal.component";
import { PreferencesService } from "./preferences.service";
import { AudioClips, AudioHelper } from "../utils/audio-helper.util";
// const Peer = peerjs.Peer;

enum CallStatus {
    Off = 'off',
    Loading = 'loading',
    On = 'on'
}

interface IRoomUser {
    socket: string;
    uid: string;
}

export interface ICallUser {
    socketID: string;
    userID: string;
    mediaStream: MediaStream;
    peerConnection: RTCPeerConnection;
    firstSDP?: boolean;
}

export interface ICallDetails {
    id: string;
    users: ICallUser[];
    localStream: MediaStream;
    screenStream: MediaStream;
    activeStream: MediaStream;
    status: CallStatus;
}

@Injectable({
    providedIn: 'root'
})
export abstract class CallService extends SocketService {

    // Required Socket Constants
    SOCKET_HOST = environment.socketHost;
    SOCKET_OPTIONS = {
        autoConnect: true,
        secure: true,
    };

    // Call Specific
    private callDetailsSub = new BehaviorSubject<null | ICallDetails>(null);
    private callServerConnectedSub = new BehaviorSubject<boolean>(false);
    private meetingDetails = new BehaviorSubject<null | IMeeting>(null);
    private meetings: IMeeting[] = [];

    private hasEchoedFirst = false;
    private callID: string;
    private peer: Peer = null;
    private status: CallStatus = CallStatus.Off;
    private connectedToCall: ICallUser[] = [];

    private localActiveStream: MediaStream = null;
    private localStream: MediaStream = null;
    private localScreenStream: MediaStream = null;

    private waitingToJoinCall = new Subject<null | ICallDetails>();

    constructor(
        private authService: AuthService,
        private analyticsService: AnalyticsService,
        private navigationService: NavigationService,
        public logService: LogService,
        private userService: UserService,
        private modalController: ModalController,
        private prefsService: PreferencesService
    ) {
        super(logService);

        // When we're logged in connect to the call server.
        // TODO: Change this to only be connected when we are actually on a call.
        this.authService.listenForAuthState().subscribe((user) => {
            if (user && !this.getSocket()) {
                this.connectToServer();
            }
        });

        this.connectionStatus.subscribe(async (e) => {
            if (e && !this.peer) {
                this.connectToPeerjs();
            } else if (!e) {
                if (this.peer) this.peer.disconnect();
                this.peer = null;
                this.callServerConnectedSub.next(false);
            }
        });

        this.navigationService.listenForMeetings().subscribe((e) => {
            this.meetings = e;
            this.checkMeetingDetails();
        });
    }

    /**
     * @description Checks meeting details and forwards them to the observable
     */
    private checkMeetingDetails() {
        if (this.meetings && this.callDetailsSub.value) {
            const found = this.meetings.find((x) => x.uid == this.callDetailsSub.value.id);
            this.meetingDetails.next(found);
        } else {
            this.meetingDetails.next(null);
        }
    }

    /**
     * @description Gets meeting details for the current call
     * @returns  
     */
    public getMeetingDetails() {
        return this.meetingDetails.asObservable();
    }

    /**
     * @description Connects to broker server and peer
     * @returns  
     */
    private async connectToServer() {
        try {
            const connected = await this.connect();

            if (!connected) {
                this.logService.log({
                    label: 'Call Service',
                    message: 'Failed to connect to server.',
                    type: LogType.Error
                });
                this.callServerConnectedSub.next(false);
                return;
            }
        } catch {
            this.callServerConnectedSub.next(false);
            return;
        }
    }

    /**
     * @description Connects to peerjs server
     * @returns  
     */
    private async connectToPeerjs() {
        try {
            const peerConnected = await this.connectPeer();

            if (!peerConnected) {
                this.logService.log({
                    label: 'Call Service',
                    message: 'Failed to connect to peer.',
                    type: LogType.Error
                });
                this.callServerConnectedSub.next(false);
                return;
            }
        } catch {
            this.callServerConnectedSub.next(false);
            return;
        }

        this.listen('room-update').subscribe(({ users }) => this.onRoomUpdate(users));
    }

    /**
     * @description Cleans id from socket.io for use with peerjs
     */
    private cleanID(id: string) {
        return id.replace(/[_-]/g, '');
    }

    /**
     * @description Connects to peer network for connections
     * @returns  
     */
    protected connectPeer() {
        return new Promise((res) => {
            this.peer = new Peer(
                this.cleanID(this.getSocket().id),
                {
                    host: environment.peer.host,
                    port: environment.peer.port,
                    path: '/peerjs',
                    secure: true,
                }
            );

            this.peer.on('call', async (call) => {
                await this.startMediaStream();
                call.answer(this.localActiveStream);
                this.onIncomingCall(call);
            });

            this.peer.on('open', (id) => {
                this.logService.log({
                    label: 'Call Service',
                    message: 'Connected'
                });
                this.callServerConnectedSub.next(true);
                res(true);
            });

            this.peer.on('error', (e) => {
                this.logService.log({
                    label: 'Call Service',
                    message: 'Error',
                    type: LogType.Error,
                    args: [e]
                });
                this.callServerConnectedSub.next(false);
                res(false);

                this.connectPeer();
            });
        });
    }

    /**
     * @description Gets default media devices for this device if we have them.
     * @returns  
     */
    private async getMediaDefaults() {
        const prefs = await this.prefsService.getSnapshot();

        return {
            audio: prefs?.devices?.audio ? prefs?.devices?.audio : null,
            video: prefs?.devices?.video ? prefs?.devices?.video : null
        };
    }

    public async enableVideo() {
        const devices = await this.getMediaDefaults();
        const video = await MediaHelper.GetMediaStream(false, true, null, devices.video);
        if (video.getVideoTracks().length == 0) return false;
        this.localStream.addTrack(video.getVideoTracks()[0]);

        this.localActiveStream = new MediaStream([
            ...this.localStream.getTracks()
        ]);

        this.connectedToCall.forEach(element => {
            element.peerConnection.close();

            const newCall = this.peer.call(this.cleanID(element.socketID), this.localActiveStream);
            element.peerConnection = newCall.peerConnection;
        });

        AudioHelper.PlaySound(AudioClips.Meeting.device_on);
        this.onCallUpdated();
        return true;
    }

    /**
     * @description Joins a call with a given ID
     * @param id 
     */
    public joinCall(id: string, audioID?: string, videoID?: string) {
        return new Promise<ICallDetails>(async (res) => {
            if (this.status !== CallStatus.Off) {
                await this.leaveCall();
            }

            // TODO: Quickly Prompt to ask for video / audio inputs.
            this.userService.updateDetails({
                uid: AuthService.auth.uid,
                meta: {
                    audio: true,
                    video: true
                }
            });

            this.status = CallStatus.Loading;

            this.waitingToJoinCall.pipe(first()).toPromise().then((e) => res(e));
            const devicesLoaded = await this.startMediaStream(videoID, audioID);

            if (devicesLoaded) {
                this.join(id, AuthService.auth.uid);
                this.callID = id;
                this.onCallUpdated();

                AudioHelper.PlaySound(AudioClips.Meeting.start);
            } else {
                res(null);
            }
        })
    }

    public joinScreen(source: MediaStream) {
        try {
            if (!source) return false;
            this.localScreenStream = source;

            this.localActiveStream = new MediaStream([
                ...this.localStream.getAudioTracks(),
                ...source.getVideoTracks()
            ]);

            source.getVideoTracks()[0].onended = () => this.endScreen();

            if (this.localStream.getVideoTracks().length == 0) {
                this.connectedToCall.forEach(element => {
                    element.peerConnection.close();

                    const newCall = this.peer.call(this.cleanID(element.socketID), this.localActiveStream);
                    element.peerConnection = newCall.peerConnection;
                });
            } else {
                this.connectedToCall.forEach(element => {
                    element.peerConnection.getSenders()[1].replaceTrack(source.getVideoTracks()[0]);
                });
            }

            AudioHelper.PlaySound(AudioClips.Meeting.screen_on);
            this.onCallUpdated();
            return true;
        } catch {
            return false;
        }
    }

    public endScreen() {
        return new Promise(() => {
            this.localActiveStream = new MediaStream([
                ...this.localStream.getTracks()
            ]);
            this.localActiveStream.getVideoTracks().forEach((e) => {
                this.localActiveStream.removeTrack(e);
            })

            if (this.localStream.getVideoTracks().length == 0) {

            } else {
                this.localActiveStream.addTrack(this.localStream.getVideoTracks()[0]);

                this.connectedToCall.forEach(element => {
                    element.peerConnection.getSenders()[1].replaceTrack(this.localStream.getVideoTracks()[0]);
                });
            }


            MediaHelper.DisposeStream(this.localScreenStream);
            this.localScreenStream = null;

            AudioHelper.PlaySound(AudioClips.Meeting.screen_off);
            this.onCallUpdated();
        })
    }

    /**
     * @description Starts media stream if one does not exist
     * @returns  
     */
    private startMediaStream(videoID?: string, audioID?: string) {
        return new Promise(async (res) => {
            if (this.localActiveStream) {
                res(true);
                return;
            }

            const devices = await this.getMediaDefaults();
            if (!videoID) devices.video;
            if (!audioID) devices.audio;

            // TODO: Get video and audio from preferences.
            MediaHelper.GetMediaStream(!!audioID, !!videoID, audioID, videoID)
                .then((e) => {
                    this.localStream = e;
                    this.localActiveStream = new MediaStream(e.getTracks());

                    if (e) {
                        res(true);
                    } else {
                        res(false);
                    }
                })
                .catch((e) => {
                    this.logService.log({
                        label: 'Call Service',
                        message: 'Error getting media stream.',
                        type: LogType.Error,
                        args: [e]
                    });
                    res(false);
                })
        });
    }

    /**
     * @description Leaves current call
     */
    public async leaveCall() {
        this.leave();
        if (this.localStream) MediaHelper.DisposeStream(this.localStream);
        if (this.localScreenStream) MediaHelper.DisposeStream(this.localScreenStream);
        this.localStream = null;
        this.localActiveStream = null
        this.status = CallStatus.Off;
        this.onCallUpdated();
        this.connectedToCall.forEach(element => {
            element.peerConnection.close();
        });
        this.connectedToCall = [];
        this.hasEchoedFirst = false;
        this.reportAnalytic(AnalyticType.Leave);
        AudioHelper.PlaySound(AudioClips.Meeting.leave);
    }

    /**
     * @description Called when the participants on the room have changed.
     * @param users 
     */
    private onRoomUpdate(users: IRoomUser[]) {
        // Check if anyone has joined.
        users.forEach(user => {
            const isOnCall = this.connectedToCall.find((x) => x.socketID === user.socket);
            if (!isOnCall) {
                this.onUserJoinedCall(user);
            }
        });

        // Check if anyone has left.
        this.connectedToCall.forEach(user => {
            const isStillConnected = users.find((x) => x.socket === user.socketID);
            if (!isStillConnected) {
                this.onUserLeftCall(user);
            }
        });

        this.onCallUpdated();
    }

    /**
     * @description Called when a call is incoming to be handled.
     * @param call 
     */
    private onIncomingCall(call: Peer.MediaConnection) {
        this.status = CallStatus.On;
        call.on('stream', (stream) => this.onUserStreamJoin(this.cleanID(call.peer), stream));
        call.on('close', () => this.onUserStreamEnd(this.cleanID(call.peer)));

        call.on('error', (e) => {
            this.logService.log({
                label: 'Call Service',
                message: 'Peer connection error',
                type: LogType.Error,
                args: [e]
            });
            this.onUserStreamEnd(this.cleanID(call.peer))
        });
    }

    /**
     * @description Called when a user joins the call
     * @param user 
     * @returns  
     */
    private onUserJoinedCall(user: IRoomUser) {
        if (user.socket === this.getSocket().id) return;

        this.connectedToCall.push({
            userID: user.uid,
            socketID: user.socket,
            mediaStream: null,
            peerConnection: null
        });

        const call = this.peer.call(this.cleanID(user.socket), this.localActiveStream);
        this.connectedToCall[this.connectedToCall.length - 1].peerConnection = call.peerConnection;
        AudioHelper.PlaySound(AudioClips.Meeting.user_joined);
    }

    /**
     * @description Called when a user leaves the call.
     * @param user 
     */
    private onUserLeftCall(user: ICallUser) {
        this.connectedToCall.splice(this.connectedToCall.findIndex((x) => x.socketID === user.socketID), 1);
        AudioHelper.PlaySound(AudioClips.Meeting.user_left);
    }

    /**
     * @description Called when a users stream comes live
     * @param id 
     * @param stream 
     */
    private onUserStreamJoin(id: string, stream: MediaStream) {
        this.connectedToCall.find((x) => this.cleanID(x.socketID) === id).mediaStream = stream;
        this.onCallUpdated();
    }

    /**
     * @description Called when a users stream ends.
     * @param id 
     */
    private onUserStreamEnd(id: string) {
        const found = this.connectedToCall.findIndex((x) => this.cleanID(x.socketID) === id);
        if (found !== -1) {
            this.connectedToCall[found].mediaStream = null;
        }
        this.onCallUpdated();
    }

    /**
     * @description Called when our call status has been updated in some way.
     */
    private onCallUpdated() {
        const details = this.status === CallStatus.Off ? null : {
            id: this.callID,
            localStream: this.localStream,
            status: this.status,
            users: this.connectedToCall,
            screenStream: this.localScreenStream,
            activeStream: this.localActiveStream
        };

        this.callDetailsSub.next(details);
        this.checkMeetingDetails();

        if (this.hasEchoedFirst === false) {
            // Called when we first join into a meeting room.
            this.hasEchoedFirst = true;
            this.waitingToJoinCall.next(details);
            this.reportAnalytic(AnalyticType.Join);
        }
    }

    /**
     * @description Gets call detail updates
     * @returns  
     */
    getCallDetails() {
        return this.callDetailsSub.asObservable();
    }

    /**
     * @description Gets call server connection status
     * @returns  
     */
    getConnectionStatus() {
        return this.callServerConnectedSub.asObservable();
    }

    /**
     * @description Shortcut to reports analytic for joining / leaving meetings
     * @param type 
     */
    private reportAnalytic(type: AnalyticType) {
        this.navigationService.listenForWorkspaces().pipe(first()).toPromise().then((e) => {
            if (e && e.current) {
                this.analyticsService.add(e.current.uid, AnalyticLocation.People, {
                    user: AuthService.auth.uid,
                    type,
                    tags: [AnalyticTag.Meeting],
                });
            }
        })
    }

    public updateCallDevices(audio: boolean, video: boolean) {
        this.emit('device-update', {
            uid: AuthService.auth.uid,
            audio, video
        });
    }

    public async showCallPreview(uid: string) {
        const modal = await this.modalController.create({
            component: JoinCallModalComponent,
            componentProps: {
                meeting: uid
            },
            cssClass: 'auto-height',
            swipeToClose: true,
            keyboardClose: true,

        });
        modal.present();
        const result = await modal.onWillDismiss();
        const data = result.data;
        return data;
    }
}