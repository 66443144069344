import { Observable } from "rxjs";
import { filter, first, last } from "rxjs/operators";

export class UtilityHelper {

    /**
     * @description Maps a value from one range to another proportionally
     */
    public static MapRange(value: number, startMin: number, startMax: number, endMin: number, endMax: number) {
        return (value - startMin) * (endMax - endMin) / (startMax - startMin) + endMin;
    }

    /**
     * @description Clamps a value between a min and max
     * @returns  
     */
    public static Clamp(val: number, min: number, max: number) {
        return Math.max(Math.min(val, max), min);
    }

    /**
     * @description Generate a random slug with a given legnth (default 5 characters)
     */
    public static RandomSlug(length = 5) {
        const characterOptions = 'ABCDEFGHJKMPQRSTUVWXYZabcdefghjkmopqrstuvwxyz';
        let tempString = '';
        for (let i = 0; i < length; i++) {
            tempString += characterOptions.charAt(Math.floor(Math.random() * characterOptions.length));
        }
        return tempString;
    }

    /**
     * @description Generate a random interger from 0 to a given number
     */
    public static RandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }

    /**
     * @description Shuffles array randomly
     */
    public static ShuffleArray(array: any[]) {
        let tempArray = [];
        let count = array.length;
        while (count !== 0) {
            const randomElement = array.splice(this.RandomInt(array.length), 1);
            tempArray = tempArray.concat(randomElement);
            count--;
        }

        return tempArray;
    }

    /**
     * @description Snaps a given value to a multiple of another
     */
    public static SnapNumber(value: number, multiple: number): number {
        return Math.round(value / multiple) * multiple;
    }

    /**
     * @description Snapshots an obserable to the first value that is returned.
     * @param observable 
     * @returns  
     */
    public static Snapshot<T>(observable: Observable<any>, ignoreNull?: boolean) {
        return observable.pipe(filter((x) => ignoreNull ? x != null : true), first()).toPromise() as Promise<T>;
    }

    /**
     * @description Makes a promise safe to use without needing a try catch returning any values or errors returned.
     * @template T The type to expect from the promise resolving
     * @param promise The promise to watch
     */
    public static async Handle<T>(promise: Promise<T>) {
        var value: T = null;
        var error: any = false;

        try {
            value = await promise;
        } catch (e) {
            value = undefined;
            error = e;
        }

        return {
            value,
            error
        };
    }

    /**
     * @description Sorts an array alphabetically
     * @param array The array to sort
     * @param propertyName Which property of the array should be used for comparison
     * @returns  
     */
    public static SortAlphabetically(array: any[], propertyName: string) {
        return array.sort((a, b) => {
            if (a[propertyName] < b[propertyName]) { return -1; }
            if (a[propertyName] > b[propertyName]) { return 1; }
            return 0;
        });
    }

    /**
     * @description Merge two arrays but only keep unique items
     */
    public static MergeUnique(...arrays: any[][]) {
        let values: any[] = [];

        arrays.forEach(array => {
            array.forEach(item => {
                if (!values.includes(item)) {
                    values.push(item);
                }
            });
        });

        return values;
    }

    /**
     * @description Shift a colours lightness by a given base10 amount
     * @param col The colour HEX to shift
     * @param amount The amount to shift the colour by.
     * @returns  
     */
    public static ColourLightness(hex: any, percent: number): string {
        let usePound = false;

        if (hex[0] == "#") {
            // col = col.slice(1);
            usePound = true;
        }

        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if (hex.length == 3) {
            hex = hex.replace(/(.)/g, '$1$1');
        }

        const r = parseInt(hex.substr(0, 2), 16),
            g = parseInt(hex.substr(2, 2), 16),
            b = parseInt(hex.substr(4, 2), 16);

        return (usePound ? '#' : '') +
            ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
    }

    /**
     * @description Converts an Hex string to the RGB components
     * @returns  
     */
    public static HexToRGB(hex: string, splitChannels?: boolean) {
        const split = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return splitChannels ? {
            r: parseInt(split[1], 16),
            g: parseInt(split[2], 16),
            b: parseInt(split[3], 16)
        } : `${parseInt(split[1], 16)}, ${parseInt(split[2], 16)}, ${parseInt(split[3], 16)}`
    }

    /**
     * @description Converts an RGB string to a hex number
     * @returns  
     */
    public static RGBToHex(rgb: string) {
        const split = rgb.split(',').map((x) => parseInt(x.trim()));
        return "#" + this.componentToHex(split[0]) + this.componentToHex(split[1]) + this.componentToHex(split[2]);
    }

    private static componentToHex(c: number) {
        let hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    public static MoveArrayItem(array: any[], from: number, to: number) {
        const item = array[from];
        array.splice(from, 1);
        array.splice(to, 0, item);
        return array;
    }

    public static Wait(milliseconds: number) {
        return new Promise<void>((res) => {
            setTimeout(() => {
                res();
            }, milliseconds);
        })
    }

    public static StripHTML(html: string) {
        return html.replace(/(<([^>]+)>)/gi, "");
    }

    private static isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    public static MergeDeep(target: any, ...sources) {
        if (!sources.length) return target;
        const source = sources.shift();
        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    this.MergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return this.MergeDeep(target, ...sources);
    }

    public static CheckMediaLink(url: string, type: 'image' | 'video') {
        if (typeof url !== 'string') return false;
        switch (type) {
            case 'image':
                return (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|webp|svg)(\?(.*))?$/gmi) !== null);
            // case 'video':
            //     return (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) !== null);
        }
    }

    public static BytesToGB(bytes: number) {
        return bytes / (1000 * 1000 * 1000);
    }

    public static PercentageOfGB(bytes: number, outOfGB: number) {
        return ((this.BytesToGB(bytes) / outOfGB) * 100).toFixed(0);
    }
}