import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../interfaces/IUser';
import { UserService } from '../services/user.service';

@Pipe({
    name: 'isPro'
})

export class ProPipe implements PipeTransform {

    constructor(
        private userService: UserService
    ) { }

    async transform(user: IUser | string): Promise<boolean> {
        if (typeof user === 'string') {
            user = await this.userService.getUserDetails(user);
        }

        return !!this.userService.hasSubscription(user);
    }
}
