import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { IUser } from 'src/app/interfaces/IUser';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';
import { UserProfileModalComponent } from '../../modals/user-profile-modal/user-profile-modal.component';

@Component({
  selector: 'app-inspect-user-popup',
  templateUrl: './inspect-user-popup.component.html',
  styleUrls: ['./inspect-user-popup.component.scss'],
})
export class InspectUserPopupComponent implements OnInit {

  @Input() user: IUser = null;
  @Input() settings = false;
  isUs = false;
  addingFriend = false;
  friendStatus = 0;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private userService: UserService,
    private paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.isUs = this.user.uid === AuthService.auth.uid;
    if (!this.isUs) {
      this.friendStatus = this.userService.getFriendStatus(this.user);
    }
  }

  onAction(id: string, data?: any) {
    if (id === 'view') {
      this.modalController.create({
        component: UserProfileModalComponent,
        componentProps: {
          user: data
        },
        cssClass: 'auto-height',
        swipeToClose: true,
        keyboardClose: true,
      }).then((e) => e.present());

      this.popoverController.dismiss(data, id);
    } else if (id === 'add') {
      this.addingFriend = true;
      this.userService.addFriend(data.uid).finally(() => {
        this.friendStatus = this.userService.getFriendStatus(this.user);
        this.addingFriend = false;
        this.popoverController.dismiss(data, id);
      });
    } else if (id === 'logout') {
      this.userService.logout();
      this.popoverController.dismiss(data, id);
    } else if (id === 'upgrade') {
      this.paymentService.upgrade();
      // this.popoverController.dismiss(data, id);
    }
  }

}
