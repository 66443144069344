import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, PopoverController } from '@ionic/angular';
import { ActionInputComponent } from '../../simple/action-interaction/action-input/action-input.component';

@Component({
  selector: 'app-simple-text-popup',
  templateUrl: './simple-text-popup.component.html',
  styleUrls: ['./simple-text-popup.component.scss'],
})
export class SimpleTextPopupComponent implements OnInit, AfterViewInit {

  @ViewChild(ActionInputComponent) ionInput: ActionInputComponent;

  @Input() header: string = '';
  @Input() button: string = '';
  @Input() placeholder: string = '';
  @Input() inputOptions: any = {};

  public isLoading = false;

  value: string = '';

  constructor(
    private popoverController: PopoverController,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.inputOptions) {
      Object.keys(this.inputOptions).forEach(element => {
        this.ionInput[element] = this.inputOptions[element];
      });
    }

    setTimeout(() => {
      this.ionInput.inputElement.nativeElement.focus();
    }, 500);
  }

  /**
   * @description Called when enter or button is pressed.
   */
  onDone() {
    this.popoverController.dismiss(this.value);
  }

  public setLoading(val: boolean) {
    this.isLoading = val;
  }

}
