import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { IUser } from 'src/app/interfaces/IUser';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';
import { UserProfileModalComponent } from '../../modals/user-profile-modal/user-profile-modal.component';
import { IHoverLinkOption } from '../../simple/hover-link/hover-link.component';

@Component({
  selector: 'app-simple-list-popup',
  templateUrl: './simple-list-popup.component.html',
  styleUrls: ['./simple-list-popup.component.scss'],
})
export class SimpleListPopupComponent {

  @Input() header = '';
  @Input() options: IHoverLinkOption[] = [];

  constructor(
    private popoverController: PopoverController,
  ) { }

  onAction(option: IHoverLinkOption, ev: MouseEvent) {
    if (option.callback) option.callback(ev);
    this.popoverController.dismiss(null, option.label);
  }

}
