import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize'
})

export class FileSizePipe implements PipeTransform {

    constructor() { }

    transform(value: number, style?: string): any {
        let formatted: { size: number; suffix: string; };
        if (typeof value !== 'number') return 'N/A';

        if (style) {
            formatted = this.format(value, style);
        } else {


            formatted = this.format(value, 'b');
            if (formatted.size < 1) return 'Empty';
            if (formatted.size > 100) formatted = this.format(value, 'kb');
            if (formatted.size > 100) formatted = this.format(value, 'mb');
            if (formatted.size > 500) formatted = this.format(value, 'gb');
            if (formatted.size > 1000) formatted = this.format(value, 'tb');
        }

        return `${formatted.size.toFixed(1).replace('.0', '')} ${formatted.suffix}`;
    }

    format(size: number, style?: string) {
        switch (style.toLowerCase()) {
            case 'b':
                return { size: size, suffix: 'bytes' };
            default:
            case 'kb':
                return { size: size * 0.001, suffix: 'kb' };
            case 'mb':
                return { size: size * 0.001 * 0.001, suffix: 'mb' };
            case 'gb':
                return { size: size * 0.001 * 0.001 * 0.001, suffix: 'gb' };
            case 'tb':
                return { size: size * 0.001 * 0.001 * 0.001 * 0.001, suffix: 'tb' };
        }
    }
}
