import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InteractionService } from 'src/app/services/interaction.service';
import { PresenceService } from 'src/app/services/presence.service';

@Component({
  selector: 'app-user-puck',
  templateUrl: './user-puck.component.html',
  styleUrls: ['./user-puck.component.scss'],
})
export class UserPuckComponent implements OnInit, OnDestroy {

  @Input() uid = '';
  @Input() onlineStatus = false;

  isOnline = false;
  presenceSub: Subscription;

  constructor(
    private interactionService: InteractionService,
    private presenceService: PresenceService
  ) { }

  ngOnInit() {
    if (this.onlineStatus) {
      this.presenceSub = this.presenceService.getUsersPresence().subscribe((e) => {
        this.isOnline = e.find((x) => x.uid === this.uid)?.online;
      });
    }
  }

  ngOnDestroy() {
    if (this.presenceSub) this.presenceSub.unsubscribe();
  }

  onClick(ev: MouseEvent) {
    this.interactionService.inspectUser(this.uid, ev);
  }

}
