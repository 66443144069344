import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app';
import { BehaviorSubject, Subscription } from "rxjs";
import { UtilityHelper } from "../utils/utility-helper.util";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    static auth: firebase.User = null;
    static sessionToken: string = UtilityHelper.RandomSlug(10);

    private authStateSub = new BehaviorSubject<firebase.User>(null);

    constructor(
        private afAuth: AngularFireAuth,
    ) { this.listenForStateChange(); }

    /**
     * @description Listens for state change
     */
    private listenForStateChange() {
        this.afAuth.authState.subscribe((e) => {
            AuthService.auth = e;
            this.authStateSub.next(e);
        });
    }

    /**
     * @description Login with an email and password 
     */
    public loginWithPassword(email: string, password: string, remember?: boolean) {
        this.afAuth.setPersistence(remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE)
        return this.afAuth.signInWithEmailAndPassword(email, password);
    }

    /**
     * @description Logins as guest
     * @returns  
     */
    public loginAsGuest() {
        this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
        return this.afAuth.signInAnonymously();
    }

    /**
    * @description Signup with an email and password
    */
    public signupWithPassword(email: string, password: string) {
        return this.afAuth.createUserWithEmailAndPassword(email, password);
    }

    /**
     * @description Signout of current session
     */
    public async signout() {
        return this.afAuth.signOut();
    }

    /**
     * @description Listens for auth state changes
     */
    public listenForAuthState() {
        return this.authStateSub.asObservable();
    }
}