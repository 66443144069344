import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AllowUserTypes, LoggedInGuard } from './pipes/logged-in.pipe';

const routes: Routes = [
  // Auth
  {
    path: '', redirectTo: 'register', pathMatch: 'full'
  },
  {
    path: 'login', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.Fresh,
        logoutGuests: true,
        redirect: '/verify'
      }
    }
  },
  {
    path: 'register', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.Fresh,
        logoutGuests: true,
        redirect: '/verify'
      }
    }
  },
  {
    path: 'verify', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.UnVerified,
        logoutGuests: true,
        redirect: '/invitation'
      }
    }
  },
  // User / Project
  {
    path: 'w',
    loadChildren: () => import('./pages/workspace/workspace.module').then(m => m.WorkspacePageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.Authorised,
        logoutGuests: true,
        redirect: '/'
      }
    }
  },
  {
    path: 'w/:project',
    loadChildren: () => import('./pages/workspace/workspace.module').then(m => m.WorkspacePageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.Authorised,
        logoutGuests: true,
        redirect: '/'
      }
    }
  },
  {
    path: 'w/:project/:topic',
    loadChildren: () => import('./pages/workspace/workspace.module').then(m => m.WorkspacePageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.Authorised,
        logoutGuests: true,
        redirect: '/'
      }
    }
  },
  {
    path: 'w/:project/:topic/:page',
    loadChildren: () => import('./pages/workspace/workspace.module').then(m => m.WorkspacePageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.Authorised,
        logoutGuests: true,
        redirect: '/'
      }
    }
  },
  {
    path: 'meeting/:project/:meeting',
    loadChildren: () => import('./pages/meeting/meeting.module').then(m => m.MeetingPageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.All
      }
    }
  },
  {
    path: 'success', redirectTo: 'w'
  },
  {
    path: 'cancel', redirectTo: 'w'
  },
  {
    path: 'invitation',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./pages/waiting/waiting.module').then(m => m.WaitingPageModule),
    canActivateChild: [LoggedInGuard],
    data: {
      loginGuard: {
        allow: AllowUserTypes.VerifiedButUnauthorised,
        logoutGuests: true,
        redirect: '/w'
      }
    }
  },
  // Mobile
  // {
  //   path: 'navigation',
  //   loadChildren: () => import('./pages/navigation/navigation.module').then( m => m.NavigationPageModule)
  // },
  // {
  //   path: 'chat',
  //   loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
