import { Directive, ElementRef, HostListener, Renderer2, Input, TemplateRef, ContentChild, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular';


@Directive({
    selector: '[tooltip]'
})
export class TooltipDirective {

    @Input('tooltip') tooltipTitle: string;
    @ContentChild('tooltipTemplate') private tooltipTemplateRef: TemplateRef<Object>;
    @Input() placement: 'top' | 'bottom' | 'left' | 'right';
    @Input() delay: number;
    tooltip: HTMLElement;
    offset = 10;

    edgeXOffset = 0;

    private DEFAULT_PLACEMENT = 'bottom';
    private DEFAULT_DELAY = 200;
    private mouseOver = false;
    private shownTitle: any = null;

    constructor(private el: ElementRef, private renderer: Renderer2, private platform: Platform, private viewContainerRef: ViewContainerRef) { }

    @HostListener('mouseenter') onMouseEnter() {
        if (this.platform.is('mobile')) { return; }
        if (!this.tooltip && (this.tooltipTitle || this.tooltipTemplateRef)) { this.show(); }
        this.mouseOver = true;
    }

    @HostListener('click') onClick() {
        if (this.platform.is('mobile')) { return; }
        if (this.tooltip) { this.hide(); }
        setTimeout(() => {
            if (this.mouseOver && (this.shownTitle !== this.tooltipTitle)) {
                this.onMouseEnter();
            }
        }, (this.delay || this.DEFAULT_DELAY));
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.platform.is('mobile')) { return; }
        if (this.tooltip) { this.hide(); }
        this.mouseOver = false;
    }

    show() {
        this.create();
        requestAnimationFrame(() => {
            this.setPosition();
            if (this.tooltip) {
                this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
            }
        })
    }

    hide() {
        this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
        setTimeout(() => {
            if (this.tooltip) {
                this.renderer.removeChild(document.body, this.tooltip);
            }
            this.tooltip = null;
        }, (this.delay || this.DEFAULT_DELAY));
    }

    create() {
        this.tooltip = this.renderer.createElement('span');

        if (this.tooltipTitle) {
            this.renderer.appendChild(
                this.tooltip,
                this.renderer.createText(this.tooltipTitle)
            );
        } else {
            const thing = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
            thing.rootNodes.forEach(node =>
                this.renderer.appendChild(this.tooltip, node));
        }

        this.renderer.appendChild(document.body, this.tooltip);
        this.renderer.addClass(this.tooltip, 'ng-tooltip');
        this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement || this.DEFAULT_PLACEMENT}`);

        this.renderer.setStyle(this.tooltip, '-webkit-transition', `${this.delay || this.DEFAULT_DELAY}ms`);
        this.renderer.setStyle(this.tooltip, '-moz-transition', `${this.delay || this.DEFAULT_DELAY}ms`);
        this.renderer.setStyle(this.tooltip, '-o-transition', `${this.delay || this.DEFAULT_DELAY}ms`);
        this.renderer.setStyle(this.tooltip, 'transition', `${this.delay || this.DEFAULT_DELAY}ms`);
        this.shownTitle = this.tooltipTitle;
    }

    setPosition() {
        if (!this.tooltip) {
            return;
        }

        const hostPos = this.el.nativeElement.getBoundingClientRect();

        const tooltipPos = this.tooltip.getBoundingClientRect();

        const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        let top, left;

        if (this.placement === 'top') {
            top = hostPos.top - tooltipPos.height - this.offset;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        }

        if (this.placement === 'bottom' || !this.placement) {
            top = hostPos.bottom + this.offset;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        }

        if (this.placement === 'left') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.left - tooltipPos.width - this.offset;
        }

        if (this.placement === 'right') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.right + this.offset;
        }

        this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
    }
}
