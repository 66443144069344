import { Injectable } from "@angular/core";
import { StripeService } from "ngx-stripe";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { WorkspaceServiceFB } from "./workspace.firebase.service";

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        private workspaceFB: WorkspaceServiceFB,
        private stripeService: StripeService
    ) { }

    async upgrade() {
        console.log('Fetching Token...');
        const token = await this.workspaceFB.getCheckoutSession(
            AuthService.auth.uid,
            AuthService.auth.email
        );
        console.log('Redirecting...');
        this.stripeService.redirectToCheckout({
            sessionId: token,
        }).subscribe((e) => {
            console.log(e)
        })
    }
}