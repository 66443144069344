import { trigger, transition, useAnimation } from "@angular/animations";
import { delayedFadeIn, fadeIn, fadeOut, popIn, popInSlow, popOut, popOutSlow } from "./animations";

export const fadeAnimations = trigger(
    'fade',
    [
        transition('void => *', [useAnimation(delayedFadeIn)]),
        transition('* => void', [useAnimation(fadeOut)])
    ]
);

export const popAnimations = trigger(
    'pop',
    [
        transition('void => *', [useAnimation(popIn)]),
        transition('* => void', [useAnimation(popOut)])
    ]
);

export const popSlowAnimations = trigger(
    'pop',
    [
        transition('void => *', [useAnimation(popInSlow)]),
        transition('* => void', [useAnimation(popOutSlow)])
    ]
);