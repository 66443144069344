import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinCallModalComponent } from './join-call-modal.component';
import { IonicModule } from '@ionic/angular';
import { ActionDropdownModule } from '../../simple/action-interaction/action-dropdown/action-dropdown.module';
import { ActionInputModule } from '../../simple/action-interaction/action-input/action-input.module';
import { ActionButtonModule } from '../../simple/action-interaction/action-button/action-button.module';



@NgModule({
  declarations: [
    JoinCallModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ActionDropdownModule,
    ActionInputModule,
    ActionButtonModule
  ],
  exports: [
    JoinCallModalComponent
  ]
})
export class JoinCallModalModule { }
