import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPuckComponent } from './user-puck.component';
import { UserPhotoModule } from '../user-photo/user-photo.module';
import { SharedModule } from 'src/app/pipes/shared.module';



@NgModule({
  declarations: [UserPuckComponent],
  imports: [
    CommonModule,
    SharedModule,
    UserPhotoModule
  ],
  exports: [UserPuckComponent],
})
export class UserPuckModule { }
