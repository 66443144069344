import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPhotoComponent implements OnInit {

  @Input() photo = '';
  @Output() photoChange = new EventEmitter<string>();

  @Input() label = '';
  @Input() editable = false;

  constructor() { }

  ngOnInit() { }

  onNewPhoto(file: File) {


    this.photoChange.emit(this.photo);
  }

}
