import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InspectUserPopupModule } from './inspect-user/inspect-user-popup.module';
import { InspectUserPopupComponent } from './inspect-user/inspect-user-popup.component';
import { SimpleTextPopupModule } from './simple-text/simple-text-popup.module';
import { SimpleTextPopupComponent } from './simple-text/simple-text-popup.component';
import { InspectMeetingPopupModule } from './inspect-meeting/inspect-meeting-popup.module';
import { InspectMeetingPopupComponent } from './inspect-meeting/inspect-meeting-popup.component';
import { SimpleListPopupModule } from './simple-list/simple-list-popup.module';
import { SimpleListPopupComponent } from './simple-list/simple-list-popup.component';
import { ContextMenuPopupModule } from './context-menu/context-menu-popup.module';
import { ContextMenuPopupComponent } from './context-menu/context-menu-popup.component';
import { InspectContentPopupModule } from './inspect-content/inspect-content-popup.module';
import { InspectContentPopupComponent } from './inspect-content/inspect-content-popup.component';
import { NewContentPopupModule } from './new-content/new-content-popup.module';
import { NewContentPopupComponent } from './new-content/new-content-popup.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    // Custom Popup Components
    InspectUserPopupModule,
    InspectMeetingPopupModule,
    SimpleTextPopupModule,
    SimpleListPopupModule,
    ContextMenuPopupModule,
    InspectContentPopupModule,
    NewContentPopupModule,
  ],
  entryComponents: [
    InspectUserPopupComponent,
    InspectMeetingPopupComponent,
    SimpleTextPopupComponent,
    SimpleListPopupComponent,
    ContextMenuPopupComponent,
    InspectContentPopupComponent,
    NewContentPopupComponent,
  ]
})
export class PopupsModule { }
