import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileModalComponent } from './user-profile-modal.component';
import { UserPhotoModule } from '../../simple/user-photo/user-photo.module';
import { UserNameModule } from '../../simple/user-name/user-name.module';
import { UserStatusModule } from '../../simple/user-status/user-status.module';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/pipes/shared.module';
import { ActionButtonModule } from '../../simple/action-interaction/action-button/action-button.module';



@NgModule({
  declarations: [UserProfileModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,

    UserPhotoModule,
    UserNameModule,
    UserStatusModule,
    ActionButtonModule
  ],
  exports: [UserProfileModalComponent],
})
export class UserProfileModalModule { }
