import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentModalComponent } from './content-modal.component';
import { UserPhotoModule } from '../../simple/user-photo/user-photo.module';
import { UserNameModule } from '../../simple/user-name/user-name.module';
import { UserStatusModule } from '../../simple/user-status/user-status.module';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/pipes/shared.module';
import { ActionButtonModule } from '../../simple/action-interaction/action-button/action-button.module';
import { ActionInputModule } from '../../simple/action-interaction/action-input/action-input.module';
import { ActionDropdownModule } from '../../simple/action-interaction/action-dropdown/action-dropdown.module';
import { UserPuckModule } from '../../simple/user-puck/user-puck.module';



@NgModule({
  declarations: [ContentModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,

    ActionButtonModule,
    ActionInputModule,
    ActionDropdownModule,
    UserNameModule,
    UserStatusModule,
    UserPuckModule
  ],
  exports: [ContentModalComponent],
})
export class ContentModalModule { }
