import { Injectable } from "@angular/core";
import { IContent, IContentFile, IContentUpload } from "../interfaces/IContent";
import { WorkspaceServiceFB } from "./workspace.firebase.service";
import firebase from 'firebase/app';
import { BehaviorSubject } from "rxjs";
import { IWorkspace } from "../interfaces/IWorkspace";
import { NavigationService } from "./navigation.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { AuthService } from "./auth.service";
import { UtilityHelper } from "../utils/utility-helper.util";

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    workspace: IWorkspace;
    content = new BehaviorSubject<IContent[]>([]);

    constructor(
        private workspaceService: WorkspaceServiceFB,
        private navService: NavigationService,
        private afStorage: AngularFireStorage
    ) {
        this.workspaceService.getContent().subscribe((e) => this.content.next(e));
        this.navService.listenForWorkspaces().subscribe((e) => this.workspace = e?.current);
    }

    public getContentListener() {
        return this.content.asObservable();
    }

    public calculateItemSize(item: IContent, deep = true): { size: number, items: number } {
        if (item.type === 'single') return { size: item.size, items: 1 };
        return (item.children || []).reduce((prev, curr) => {
            const item = this.content.value.find((x) => x.uid === curr)

            if (deep) {
                const size = item ? this.calculateItemSize(item) : null;

                return {
                    size: prev.size + (size ? size.size : 0),
                    items: prev.items + (size ? size.items : 1) + (item.type === 'folder' ? 1 : 0)
                }
            } else {
                return {
                    size: prev.size + (item ? item.size : 0),
                    items: prev.items + 1
                }
            }
        }, { size: 0, items: 0 })
    }

    public async setContent(data: IContent) {
        const uid = await this.workspaceService.setContent(this.workspace.uid, {
            ...data,
            updated: firebase.firestore.FieldValue.serverTimestamp() as any
        });

        if (data.parent && !data.parent.startsWith('topic_')) {
            await this.workspaceService.setContent(this.workspace.uid, {
                uid: data.parent,
                children: firebase.firestore.FieldValue.arrayUnion(uid)
            } as any)
        }
        return uid;
    }

    public async deleteContent(data: IContent) {
        if (data.type === 'single') {
            // If we are a content item then we need to clean-up and content that might be left over...
        }

        await this.workspaceService.deleteContent(this.workspace.uid, data);

        if (data.parent && !data.parent.startsWith('topic_')) {
            await this.workspaceService.setContent(this.workspace.uid, {
                uid: data.parent,
                children: firebase.firestore.FieldValue.arrayRemove(data.uid)
            } as any)
        }
        return;
    }

    public async deleteFile(file: IContentFile) {
        const ref = this.afStorage.refFromURL(file.link);
        await UtilityHelper.Snapshot(ref.delete());
        return;
    }

    public async uploadFileInto(file: File, item: IContent, callback: (progress: IContentUpload) => void): Promise<IContentFile> {
        // TODO: Frontend checks for filesize - permissions and paid users get more etc...
        return new Promise<IContentFile>((res) => {
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const fileName = name.substring(0, lastDot);
            const extension = name.substring(lastDot + 1);
            const versionNumber = (item.versions || []).length + 1;

            const progress: IContentUpload = {
                total: file.size,
                current: 0,
                percent: 0
            };

            const fileRef = this.afStorage.ref(`workspaces/${this.workspace.uid}/content/${item.uid}/${item.name}.v${versionNumber}.${extension}`);
            callback(progress);

            const task = fileRef.put(file);

            task.snapshotChanges().subscribe(async (e) => {
                progress.total = e.totalBytes;
                progress.current = e.bytesTransferred;
                progress.percent = e.bytesTransferred / e.totalBytes;
                callback(progress);

                if (e.state === 'running') return;

                if (e.state === 'success') {
                    progress.percent = 1;
                    callback(progress);
                    const link = await UtilityHelper.Snapshot<string>(fileRef.getDownloadURL());

                    res({
                        size: file.size,
                        link,
                        name: fileName || 'Untitled',
                        extension,
                        sender: AuthService.auth.uid,
                        created: firebase.firestore.Timestamp.fromDate(new Date())
                    });
                };

                // TODO: Handle error potentially??
            })
        });
    }


}