export interface IAnalytic {
    key: AnalyticLocation;
    report_count: number;
    reports: IAnalyticReport[];
}

export interface IAnalyticReport {
    user: string;
    tags: AnalyticTag[];
    type: AnalyticType;
    message?: string;
    date?: firebase.default.firestore.Timestamp
}

export enum AnalyticLocation {
    Management = 'management',
    Files = 'files',
    People = 'people',
    Tasks = 'tasks',
    Analytics = 'analytics',
    Settings = 'settings',
    Permissions = 'permissions',
    Board = 'Board'
}

export enum AnalyticTag {
    Workspace = 'workspace',
    Topic = 'topic',
    User = 'user',
    Meeting = 'Meeting',
    Widget = 'Widget'
}

export enum AnalyticType {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Join = 'join',
    Leave = 'leave'
}