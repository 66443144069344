import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { Thumbs } from 'swiper';
import { IUser } from '../interfaces/IUser';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { UtilityHelper } from '../utils/utility-helper.util';

@Pipe({
  name: 'userDetails'
})

export class UserDetailsPipe implements PipeTransform {

  public static usersFetched: { [key: string]: IUser } = {};

  constructor(
    private userService: UserService,
  ) { }

  async transform(value: any, detail = null) {
    let details: IUser = (typeof value === 'string') ? null : value;

    if (!details) {
      if (AuthService.auth.uid === value) {
        details = await UtilityHelper.Snapshot<IUser>(this.userService.getOurDetails(), true);
      } else {
        details = await (!value ? UtilityHelper.Snapshot<IUser>(this.userService.getOurDetails(), true) : this.getDetails(value));
      }
    }

    const rgb = details.prefs ? details.prefs.accent : getComputedStyle(document.body).getPropertyValue('--theme-primary');
    const rgb_alt = details.prefs?.accent_alt;

    switch (detail || '') {
      default:
        return details[detail];
      case '':
        return details;
      case 'rgb':
        return rgb;
      case 'rgb-alt':
        return rgb_alt || UtilityHelper.HexToRGB(UtilityHelper.ColourLightness(UtilityHelper.RGBToHex(rgb), 30));
      case 'color':
        return `rgba(${rgb}, 1)`;
      case 'color-alt':
        return `rgba(${rgb_alt || UtilityHelper.HexToRGB(UtilityHelper.ColourLightness(UtilityHelper.RGBToHex(rgb), 30))}, 1)`;
      case 'displayName':
        return (AuthService?.auth?.uid === details.uid) ? 'You' : details.name + (details.guest ? ' (Guest)' : '');
    }
  }

  async getDetails(uid: string) {
    if (!UserDetailsPipe.usersFetched[uid]) {
      UserDetailsPipe.usersFetched[uid] = await this.userService.getUserDetails(uid);
    }
    return UserDetailsPipe.usersFetched[uid];
  }
}
