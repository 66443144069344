export const environment = {
  production: false,
  socketHost: 'https://api.knapsack-app.com:51101',
  peer: {
    host: 'api.knapsack-app.com',
    port: 51102
  },
  stripe: "pk_test_51JXmpjGOxvaiR9IEaF6LbUFIZDjTmJHLe9ZB1zkrLPpP00sOErGNIrmddPa6cPLYz7emruNp1MMlTrAb1PaAHWrM0080vgfHc4",
  firebase: {
    apiKey: "AIzaSyDjACdfFY3OvXNxDU6_TLIxmhhhp6R1KC0",
    authDomain: "knapsack-versionmaybe.firebaseapp.com",
    projectId: "knapsack-versionmaybe",
    storageBucket: "knapsack-versionmaybe.appspot.com",
    messagingSenderId: "687505850509",
    appId: "1:687505850509:web:682e4e07fa6333570da2c4",
    measurementId: "G-5NEJJ1LSGV"
  },
  devPrefix: "beta"
};