export class MediaHelper {

    /**
     * @description Gets devlice limitations and connected devices
     */
    public static async GetAvailableDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return {
            audio_in: !!devices.find((x) => x.kind === 'audioinput' && x.label),
            audio_out: !!devices.find((x) => x.kind === 'audiooutput' && x.label),
            video_in: !!devices.find((x) => x.kind === 'videoinput' && x.label)
        };
    }

    /**
     * @description Gets media stream used for video/audio calls
     */
    public static GetMediaStream(audio: boolean, video: boolean, audioID?: string, videoID?: string) {
        return new Promise<MediaStream>(async (res) => {
            const devicesAvailable = await this.GetAvailableDevices();
            const vc = video && devicesAvailable.video_in && (videoID ? {
                deviceId: videoID
            } : {
                facingMode: 'user',
                echoCancellation: true,
                noiseSuppression: true,
            });
            const ac = audio && devicesAvailable.audio_in && (audioID ? {
                deviceId: audioID
            } : true);

            navigator.mediaDevices.getUserMedia({
                video: vc,
                audio: ac,
            })
                .then((e) => res(e))
                .catch((e) => {
                    res(null)
                });
        })
    }

    /**
     * @description Gets screen stream source
     */
    public static GetScreenStream() {
        return new Promise<MediaStream>(async (res) => {
            const mediaDevices = navigator.mediaDevices as any;
            const options = {
                audio: false,
                video: true
            }
            mediaDevices.getDisplayMedia(options)
                .then((e) => res(e))
                .catch(() => res(null))
        })
    }

    /**
     * @description Disposes of a stream
     * @param stream 
     */
    public static DisposeStream(stream: MediaStream) {
        stream.getTracks().forEach(track => track.stop());
    }

    /**
     * @description Converts RGB to 
     * @param r RED or Full RGB Comma Seperated String
     * @param [g] GREEN
     * @param [b] BLUE
     * @returns to hex 
     */
    public static rgbToHex(r: any, g?: any, b?: any): string {
        if (g == undefined && b == undefined && r != undefined) {
            const temp = r.split(',');
            r = temp[0].trim();
            g = temp[1].trim();
            b = temp[2].trim();
        }

        const rHex = this.rgbComponentToHex(r);
        const gHex = this.rgbComponentToHex(g);
        const bHex = this.rgbComponentToHex(b);
        return `#${rHex}${gHex}${bHex}`;
    }

    /**
     * @description Convert rgb component number to hex number
     */
    private static rgbComponentToHex(num: number) {
        let numHex = Number(num).toString(16);
        if (numHex.length < 2) {
            numHex = '0' + numHex;
        }
        return numHex;
    }

    /**
     * @description Copys some text to the clipboard
     * @param text 
     */
    public static copyToClipboard(text: string | HTMLElement, removeAfter?: boolean) {
        // TODO: Needs modernising / a bit of a refactor...
        if (typeof text === 'string') {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(text);
            } else {
                const el = document.createElement('textarea');
                el.value = text;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            }
        } else {
            const range = document.createRange();
            const selection = window.getSelection();
            if (selection.type !== 'Range' || !text.contains(selection.focusNode)) {
                range.selectNode(text);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }

            document.execCommand('copy');
            if (removeAfter) window.getSelection().removeAllRanges();
        }
    }

    public static GetLocalFile(options: {
        multiple?: boolean,
        allowedTypes?: string[]
    }) {
        return new Promise<File[]>((res) => {
            const element = document.createElement('input');
            element.type = 'file';
            element.accept = options.allowedTypes?.join(', ');
            element.multiple = options.multiple;
            element.addEventListener('change', (ev) => {
                res(Array.from(element.files))
            });
            element.click();
        });
    }

}