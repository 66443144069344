import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { INavigationWorkspaces } from 'src/app/interfaces/INavigation';
import { IPresenceUser } from 'src/app/interfaces/IPresence';
import { IUser, UserFriendStatus } from 'src/app/interfaces/IUser';
import { IWorkspace, IWorkspaceRole } from 'src/app/interfaces/IWorkspace';
import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PresenceService } from 'src/app/services/presence.service';
import { UserService } from 'src/app/services/user.service';
import { WorkspaceServiceFB } from 'src/app/services/workspace.firebase.service';
import { UtilityHelper } from 'src/app/utils/utility-helper.util';

@Component({
  selector: 'app-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.scss'],
})
export class UserProfileModalComponent implements OnInit, OnDestroy {

  @Input() user: IUser;
  isUs = false;
  workspaceRole: IWorkspaceRole;
  workspace: IWorkspace = null;
  presence: IPresenceUser;

  friendLoading = false;
  get friendStatus() {
    return (UserService?.details?.friends || {})[this.user.uid] || UserFriendStatus.NotFriends;
  }

  presenceSub: Subscription

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private presenceService: PresenceService,
    private logService: LogService
  ) { }

  async ngOnInit() {
    this.isUs = this.user.uid === AuthService.auth.uid;
    this.workspaceRole = this.navigationService.getRole(this.user.uid);
    this.workspace = (await UtilityHelper.Snapshot<INavigationWorkspaces>(this.navigationService.listenForWorkspaces())).current;
    this.presenceSub = this.presenceService.getUsersPresence().subscribe((e) => {
      this.presence = e.find((x) => x.uid === this.user.uid);
    })
  }

  ngOnDestroy() {
    if (this.presenceSub) { this.presenceSub.unsubscribe(); }
  }

  addFriend() {
    this.friendLoading = true;
    this.userService.addFriend(this.user.uid).finally(() => {
      this.friendLoading = false;
    });
  }

  removeFriend() {
    this.friendLoading = true;
    this.userService.removeFriend(this.user.uid).finally(() => {
      this.friendLoading = false;
    });
  }

  onNewPhoto(photo: string) {
    this.logService.log({
      label: 'User Profile',
      message: 'Uploaded Photo',
      args: [photo]
    });
  }

}
